// /* image hover effect */

.fig-hover {
  &:after {
    content: ' ';
    clear: both;
    display: block;
  }
  & > div {
    position: relative;
    & > span {
      position: absolute;
      bottom: -20px;
      left: 0;
      z-index: -1;
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;
      color: #444;
      font-size: 18px;
      text-decoration: none;
      text-align: center;
      -webkit-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
      opacity: 0;
    }
  }
  figure {
    width: 100%; // height: 100%;
    margin: 0;
    padding: 0;
    background: $color-white;
    overflow: hidden;
    &:hover + span {
      bottom: -36px;
      opacity: 1;
    }
  }
}

/* Opacity #2 */

.hover-opacity figure {
  background: #ffffff;
}

.hover-opacity figure img {
  opacity: 1;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.hover-opacity figure:hover img {
  opacity: 0.9;
}

/* Flashing */

.hover13 figure:hover img {
  opacity: 1;
  -webkit-animation: flash 1.5s;
  animation: flash 1.5s;
}

@-webkit-keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

/* Shine */

.shine {
  figure {
    position: relative;
    &:before {
      position: absolute;
      top: 0;
      left: -75%;
      z-index: 2;
      display: block;
      content: '';
      width: 50%;
      height: 100%;
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
      -webkit-transform: skewX(-25deg);
      transform: skewX(-25deg);
    }
    &:hover {
      &:before {
        -webkit-animation: shine 0.75s;
        animation: shine 0.75s;
      }
    }
  }
  &:hover {
    figure {
      &:before {
        -webkit-animation: shine 0.75s;
        animation: shine 0.75s;
      }
    }
  }
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

/* Zoom In #2 */

.zoomin figure img {
  width: 100%;
  overflow: hidden;
  height: auto;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.zoomin figure:hover img {
  transform: scale(1.5);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.bg-white {
  background-color: $color-white;
}
.bg-black {
  background-color: $color-black !important;
}
.opacity-1 {
  opacity: 1 !important;
}
.bold {
  font-weight: 700 !important;
}

.color-secondary{
  color: $color-secondary;
}