.footer {
    background-color: $color-gray-800;
    padding-top: $px70;
    padding-bottom: $px35;
    .copy{
        color: rgba($color-white,0.4);
        text-align: center;
    }
}
.footer-logo {
    margin-bottom: $px20;
}
.social-box {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        li {
            & + li {
                margin-left: $px20;
            }
            a {
                color: $color-gray-700;
                &:hover,
                &:focus {
                    color: $color-primary;
                }
            }
        }
    }
}
.footer-link{
    h3{
        font-size: $px20;
        color: $color-white;
        font-family: $ffamily-700;
        text-transform: uppercase;
        margin-bottom: $px20;
        font-weight: 400;
    }
    ul{
        padding: 0;margin: 0;list-style: none;
        li{
            margin-bottom: $px15;
            a{
                color: $color-gray-600;
                font-family: $ffamily-book;
                text-transform: capitalize;
                position: relative;
                padding-left: $px16;
                &::before{
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5.938' height='10.385' viewBox='0 0 5.938 10.385'%3E%3Cpath id='Icon_ionic-ios-arrow-forward' data-name='Icon ionic-ios-arrow-forward' d='M15.394,11.387,11.464,7.46a.739.739,0,0,1,0-1.048.748.748,0,0,1,1.051,0l4.452,4.449a.741.741,0,0,1,.022,1.023l-4.471,4.48a.742.742,0,0,1-1.051-1.048Z' transform='translate(-11.246 -6.196)' fill='%239f9f9f'/%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-position: left top;
                    content: " ";
                    width: 6px;
                    height: 10px;
                    position: absolute;
                    left: 0;
                    top: 2px;
                    @include transition(all, 0.2s, ease-in-out);
                }
                &:hover{
                    color: $color-primary;
                    &::before{
                        left: $px2;
                    }
                }
            }
        }
    }
}