/* You can add global styles to this file, and also import other style files */
/*
 *  Owl Carousel - Core
 */
@import 'https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css';
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
  	This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
  	calculation of the height of the owl-item that breaks page layouts
   */
}
.owl-carousel .owl-item .owl-lazy {
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

/* @include transition(all,2s,ease-out); */
@font-face {
  font-family: "Circular-Std-Regular";
  src: url("/assets/fonts/CircularStd-Regular.woff2") format("woff2"), url("/assets/fonts/CircularStd-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Circular-Std-Medium";
  src: url("/assets/fonts/CircularStd-Medium.woff2") format("woff2"), url("/assets/fonts/CircularStd-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Circular-Std-MediumItalic";
  src: url("/assets/fonts/CircularStd-MediumItalic.woff2") format("woff2"), url("/assets/fonts/CircularStd-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Circular-Std-Bold";
  src: url("/assets/fonts/CircularStd-Bold.woff2") format("woff2"), url("/assets/fonts/CircularStd-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Circular-Std-BoldItalic";
  src: url("/assets/fonts/CircularStd-BoldItalic.woff2") format("woff2"), url("/assets/fonts/CircularStd-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Circular-Std-Black";
  src: url("/assets/fonts/CircularStd-Black.woff2") format("woff2"), url("/assets/fonts/CircularStd-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Circular-Std-BlackItalic";
  src: url("/assets/fonts/CircularStd-BlackItalic.woff2") format("woff2"), url("/assets/fonts/CircularStd-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Circular-Std-Book";
  src: url("/assets/fonts/CircularStd-Book.woff2") format("woff2"), url("/assets/fonts/CircularStd-Book.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Circular-Std-BookItalic";
  src: url("/assets/fonts/CircularStd-BookItalic.woff2") format("woff2"), url("/assets/fonts/CircularStd-BookItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
.family-400 {
  font-family: "Circular-Std-Regular", sans-serif !important;
}

.family-600 {
  font-family: "Circular-Std-Medium", sans-serif !important;
}

.family-600-italic {
  font-family: "Circular-Std-MediumItalic", sans-serif !important;
}

.family-700 {
  font-family: "Circular-Std-Bold", sans-serif !important;
}

.family-700-italic {
  font-family: "Circular-Std-BoldItalic", sans-serif !important;
}

.family-900 {
  font-family: "Circular-Std-Black", sans-serif !important;
}

.family-900-italic {
  font-family: "Circular-Std-BlackItalic", sans-serif !important;
}

.family-book {
  font-family: "Circular-Std-Book", sans-serif !important;
}

.family-book-italic {
  font-family: "Circular-Std-BookItalic", sans-serif !important;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 4.125rem 0 0;
  font-family: "Circular-Std-Regular", sans-serif;
  font-weight: 400;
  color: #020000;
  font-size: 0.875rem;
}
@media (min-width: 768px) {
  body {
    padding-top: 5.625rem;
  }
}

a,
button {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

a {
  color: #FCB606;
  text-decoration: none;
}

a:hover {
  color: #e6990b;
  text-decoration: none;
}

input:focus,
button:focus,
a:focus {
  outline-width: 0;
  outline-style: solid;
  outline-color: transparent;
}

button:focus,
a:focus {
  outline: none;
}

img {
  max-width: 100%;
}

.fig-hover:after {
  content: " ";
  clear: both;
  display: block;
}
.fig-hover > div {
  position: relative;
}
.fig-hover > div > span {
  position: absolute;
  bottom: -20px;
  left: 0;
  z-index: -1;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 18px;
  text-decoration: none;
  text-align: center;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  opacity: 0;
}
.fig-hover figure {
  width: 100%;
  margin: 0;
  padding: 0;
  background: #fff;
  overflow: hidden;
}
.fig-hover figure:hover + span {
  bottom: -36px;
  opacity: 1;
}

/* Opacity #2 */
.hover-opacity figure {
  background: #ffffff;
}

.hover-opacity figure img {
  opacity: 1;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.hover-opacity figure:hover img {
  opacity: 0.9;
}

/* Flashing */
.hover13 figure:hover img {
  opacity: 1;
  -webkit-animation: flash 1.5s;
  animation: flash 1.5s;
}

@-webkit-keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
/* Shine */
.shine figure {
  position: relative;
}
.shine figure:before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
.shine figure:hover:before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}
.shine:hover figure:before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
/* Zoom In #2 */
.zoomin figure img {
  width: 100%;
  overflow: hidden;
  height: auto;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.zoomin figure:hover img {
  transform: scale(1.5);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.bg-white {
  background-color: #fff;
}

.bg-black {
  background-color: #000000 !important;
}

.opacity-1 {
  opacity: 1 !important;
}

.bold {
  font-weight: 700 !important;
}

.color-secondary {
  color: #1F2B59;
}

.small-heading {
  color: #1F2B59;
  font-size: 1.125rem;
  font-family: "Circular-Std-Bold", sans-serif;
  position: relative;
  padding-left: 1.5rem;
  min-height: 2rem;
  display: flex;
  align-items: center;
}
.small-heading::before {
  position: absolute;
  content: " ";
  width: 0.375rem;
  height: 100%;
  background-color: #FCB606;
  left: 0;
}

.hdd-1 {
  font-family: "Circular-Std-Black", sans-serif;
  color: #020000;
  font-size: 1.625rem;
}
@media (min-width: 768px) {
  .hdd-1 {
    font-size: 2.25rem;
  }
}

.hdd-2 {
  font-family: "Circular-Std-Bold", sans-serif;
  color: #020000;
  margin-bottom: 2.188rem;
  font-size: 1.625rem;
}
@media (min-width: 768px) {
  .hdd-2 {
    font-size: 2.25rem;
  }
}

.hdd-3 {
  position: relative;
  font-family: "Circular-Std-Bold", sans-serif;
  color: #020000;
  margin-bottom: 2.188rem;
  padding-bottom: 1.875rem;
  margin-bottom: 1.875rem;
  font-size: 1.625rem;
}
@media (min-width: 768px) {
  .hdd-3 {
    font-size: 2.25rem;
  }
}
.hdd-3::after {
  position: absolute;
  content: " ";
  left: 0;
  bottom: 0;
  background-color: #FCB606;
  border-radius: 0.3125rem;
  height: 0.5625rem;
  width: 3.75rem;
}

.hdd-4, .faq-hero .hdd-4 {
  color: #020000;
  font-family: "Circular-Std-Black", sans-serif;
  font-size: 1.875rem;
}
@media (min-width: 992px) {
  .hdd-4, .faq-hero .hdd-4 {
    font-size: 2.875rem;
  }
}

.hdd-5 {
  color: #020000;
  font-size: 1.25rem;
  font-family: "Circular-Std-Black", sans-serif;
  margin-bottom: 0;
}

.hdd-6 {
  color: #020000;
  font-size: 1.875rem;
  font-family: "Circular-Std-Bold", sans-serif;
}

.text-black {
  color: #000000;
}

.hr-black {
  background-color: #000000;
}

.fs-15 {
  font-size: 0.875rem;
}

.fs-20 {
  font-size: 1.25rem;
}

.form-select-xl {
  border-radius: 0.5rem;
  border: 1px solid #8e9fc3;
  background-image: url("data:image/svg+xml,%3Csvg id='Group_3' data-name='Group 3' xmlns='http://www.w3.org/2000/svg' width='11.701' height='12.701' viewBox='0 0 11.701 12.701'%3E%3Cpath id='Icon_ionic-md-arrow-dropup' data-name='Icon ionic-md-arrow-dropup' d='M9,19.351,14.851,13.5,20.7,19.351Z' transform='translate(-9 -13.5)' fill='%23485885'/%3E%3Cpath id='Icon_ionic-md-arrow-dropup-2' data-name='Icon ionic-md-arrow-dropup' d='M9,13.5l5.851,5.851L20.7,13.5Z' transform='translate(-9 -6.649)' fill='%23485885'/%3E%3C/svg%3E%0A");
  background-size: 12px 13px;
  height: 2.875rem;
  font-family: "Circular-Std-Book", sans-serif;
  color: #63749B;
}
.form-select-xl:disabled {
  background-color: #fff;
  opacity: 0.5;
}
.form-select-xl:focus {
  color: #1F2B59;
  background-color: #fff;
  border-color: #1F2B59;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(31, 43, 89, 0.25);
}

.form-control-xl {
  border-radius: 0.5rem;
  border: 1px solid #8e9fc3;
  height: 2.875rem;
  font-family: "Circular-Std-Book", sans-serif;
  color: #63749B;
}
.form-control-xl:disabled {
  background-color: #fff;
  opacity: 0.5;
}
.form-control-xl:focus {
  color: #1F2B59;
  background-color: #fff;
  border-color: #1F2B59;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(31, 43, 89, 0.25);
}

.custome-form-controls .form-label {
  color: #63749B;
  font-family: "Circular-Std-Book", sans-serif;
  margin-bottom: 0.3125rem;
}
.custome-form-controls .control-line {
  position: relative;
}
.custome-form-controls .control-line::before {
  background-color: #FCB606;
  width: 0.5rem;
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 8px 0 0 8px;
}
.custome-form-controls .form-control {
  border-color: #8E9FC3;
  border-radius: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  min-height: 2.875rem;
}
.custome-form-controls .form-control:focus {
  color: #1F2B59;
  background-color: #fff;
  border-color: #1F2B59;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(31, 43, 89, 0.25);
}

.link-secondary {
  color: #1F2B59;
}

.btn-group-xl .btn {
  padding: 10px 0.625rem;
}
.btn-group-xl .btn-primary {
  background-color: #fff;
  border-color: #8E9FC3;
  color: #63749B;
  border-radius: 0.5rem;
}
.btn-group-xl .btn-check:checked + .btn-primary,
.btn-group-xl .btn-check:active + .btn-primary,
.btn-group-xl .btn-primary:active,
.btn-group-xl .btn-primary.active,
.btn-group-xl .show > .btn-primary.dropdown-toggle {
  background-color: #FCB606;
  border-color: #FCB606;
}
.btn-group-xl .btn-check:checked + .btn-primary:focus,
.btn-group-xl .btn-check:active + .btn-primary:focus,
.btn-group-xl .btn-primary:active:focus,
.btn-group-xl .btn-primary.active:focus,
.btn-group-xl .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(252, 182, 6, 0.5);
}
.btn-group-xl .btn-check:focus + .btn-primary, .btn-group-xl .btn-primary:focus {
  color: #fff;
  background-color: #FCB606;
  border-color: #FCB606;
  box-shadow: 0 0 0 0.25rem rgba(252, 182, 6, 0.5);
}

.px-35 {
  padding-left: 2.188rem;
  padding-right: 2.188rem;
}

.btn-main {
  color: #fff;
  background-color: #FCB606;
  border-color: #FCB606;
  font-weight: 400;
  border-radius: 5px;
  min-height: 46px;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  font-family: "Circular-Std-Medium", sans-serif;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.16);
  min-width: 8.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-main:hover {
  color: #fff;
  background-color: #e6990b;
  border-color: #e6990b;
}
.btn-main:focus, .btn-main.focus {
  color: #fff;
  background-color: #e6990b;
  border-color: #e6990b;
  box-shadow: 0 0 0 0.2rem rgba(252, 182, 6, 0.5);
}
.btn-main.disabled, .btn-main:disabled {
  color: #fff;
  background-color: #7c7c7c;
  border-color: #7c7c7c;
}
.btn-main:not(:disabled):not(.disabled):active, .btn-main:not(:disabled):not(.disabled).active,
.btn-main .show > .btn-main.dropdown-toggle {
  color: #fff;
  background-color: #e6990b;
  border-color: #FCB606;
}
.btn-main:not(:disabled):not(.disabled):active:focus, .btn-main:not(:disabled):not(.disabled).active:focus,
.btn-main .show > .btn-main.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 182, 6, 0.5);
}
.btn-main.btn-sm {
  min-height: 2.5rem;
  color: #020000;
  font-family: "Circular-Std-Book", sans-serif;
}
.btn-main.btn-sm i {
  margin-right: 0.625rem;
  min-width: 17px;
}
.btn-main.btn-sm:hover {
  color: #fff;
}

.btn-outline-main {
  color: #FCB606;
  border-color: #FCB606;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.16);
}
.btn-outline-main:hover {
  color: #020000;
  background-color: #FCB606;
  border-color: #FCB606;
}
.btn-outline-main:focus {
  box-shadow: 0 0 0 0.25rem rgba(252, 182, 6, 0.5);
}

.add-to-favourite {
  color: #FCB606;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.add-to-favourite .heart-stroke {
  color: #FCB606;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.add-to-favourite .heart-fill {
  color: transparent;
}
.add-to-favourite:hover, .add-to-favourite.active {
  color: #FCB606;
}
.add-to-favourite:hover .heart-stroke, .add-to-favourite.active .heart-stroke {
  color: #FCB606;
}
.add-to-favourite:hover .heart-fill, .add-to-favourite.active .heart-fill {
  color: #FCB606;
}

.pdf-download {
  display: inline-flex;
  font-size: 1rem;
  color: #020000;
  font-family: "Circular-Std-Book", sans-serif;
  align-items: center;
}
.pdf-download i {
  margin-right: 0.625rem;
}
.pdf-download i svg,
.pdf-download i img {
  display: block;
}

.product-price-block {
  background-color: #FFFBF2;
  border: 1px solid #FCB606;
  border-radius: 8px;
  padding: 1.5rem;
}
.product-price-block h2,
.product-price-block h3,
.product-price-block h4,
.product-price-block h5 {
  font-family: "Circular-Std-Bold", sans-serif;
  color: #020000;
}
.product-price-block h2 {
  font-size: 2.375rem;
  margin-bottom: 1.25rem;
  text-align: center;
}
.product-price-block h3 {
  text-transform: uppercase;
  font-size: 1.625rem;
  margin-bottom: 0.3125rem;
}
.product-price-block h4 {
  font-size: 1rem;
  margin-bottom: 0.3125rem;
}
.product-price-block h5 {
  font-family: "Circular-Std-Book", sans-serif;
  font-size: 0.875rem;
  margin-bottom: 1.25rem;
}

.header {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.header .navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}
.header .navbar-brand img {
  height: 50px;
}
@media (min-width: 768px) {
  .header .navbar-brand img {
    height: auto;
  }
}
@media (min-width: 768px) {
  .header .navbar-nav.primary-nav .nav-item + .nav-item {
    margin-left: 0.9375rem;
  }
}
.header .navbar-nav.primary-nav .nav-link {
  font-size: 0.875rem;
  color: #1F2B59;
  font-family: "Circular-Std-Book", sans-serif;
  position: relative;
}
.header .navbar-nav.primary-nav .nav-link::after {
  content: " ";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 6px;
  background-color: #FCB606;
  text-transform: uppercase;
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.header .navbar-nav.primary-nav .nav-link:hover, .header .navbar-nav.primary-nav .nav-link:focus, .header .navbar-nav.primary-nav .nav-link.active {
  color: #FCB606;
}
.header .navbar-nav.primary-nav .nav-link:hover::after, .header .navbar-nav.primary-nav .nav-link:focus::after, .header .navbar-nav.primary-nav .nav-link.active::after {
  transform: scale(1);
  opacity: 0.36;
}
.header .navbar-nav.primary-nav .nav-link .icon {
  height: 1.375rem;
  display: block;
}
.header .navbar-nav.primary-nav .nav-link .icon svg {
  height: 22px;
}
.header .navbar-nav.secondary-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.header .navbar-nav.secondary-nav .nav-item + .nav-item {
  margin-left: 1.25rem;
}
.header .navbar-nav.secondary-nav .nav-link {
  font-size: 0.875rem;
  position: relative;
  color: #1F2B59;
  font-family: "Circular-Std-Medium", sans-serif;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.header .navbar-nav.secondary-nav .nav-link:hover, .header .navbar-nav.secondary-nav .nav-link:focus, .header .navbar-nav.secondary-nav .nav-link.active {
  color: #FCB606;
}
.header .navbar-nav.secondary-nav .nav-link.btn-signin {
  background-color: #FCB606;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.16);
  border-radius: 0.5rem;
  color: #fff;
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  display: flex;
  align-items: center;
}
.header .navbar-nav.secondary-nav .nav-link.btn-signin:hover, .header .navbar-nav.secondary-nav .nav-link.btn-signin:focus, .header .navbar-nav.secondary-nav .nav-link.btn-signin.active {
  background-color: #e6990b;
}
.header .navbar-nav.secondary-nav .nav-link.btn-signin i {
  display: block;
  margin-right: 0.375rem;
  max-width: 1.063rem;
}
.header .navbar-nav.secondary-nav .nav-link.btn-signin span {
  display: block;
  line-height: 1;
}
.header .navbar-nav.secondary-nav .user-dropdown {
  margin-left: 10px;
}
.header .navbar-nav.secondary-nav .user-dropdown .nav-link {
  display: flex;
  align-items: center;
}
.header .navbar-nav.secondary-nav .user-dropdown .username {
  display: none;
}
@media (min-width: 768px) {
  .header .navbar-nav.secondary-nav .user-dropdown .username {
    display: inline-block;
  }
}
.header .navbar-nav.secondary-nav .user-dropdown figure {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  overflow: hidden;
  margin: 0;
  margin-right: 0.625rem;
}
.header .navbar-nav.secondary-nav .user-dropdown figure img {
  object-fit: cover;
  object-position: center;
}
.header .navbar-nav.secondary-nav .user-dropdown .dropdown-menu {
  background-color: #020000;
}
@media (max-width: 991.98px) {
  .header .navbar-nav.secondary-nav .user-dropdown .dropdown-menu {
    position: absolute;
    right: 0;
  }
}
.header .navbar-nav.secondary-nav .user-dropdown .dropdown-menu .dropdown-item {
  color: #fff;
  display: flex;
  align-items: center;
}
.header .navbar-nav.secondary-nav .user-dropdown .dropdown-menu .dropdown-item i {
  width: 1.125rem;
  height: 1.125rem;
  display: inline-block;
  margin-right: 0.5rem;
}
.header .navbar-nav.secondary-nav .user-dropdown .dropdown-menu .dropdown-item i img {
  display: block;
}
.header .navbar-nav.secondary-nav .user-dropdown .dropdown-menu .dropdown-item:hover, .header .navbar-nav.secondary-nav .user-dropdown .dropdown-menu .dropdown-item.active {
  background-color: #FCB606;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(252, 182, 6, 0.5);
}

.footer {
  background-color: #020000;
  padding-top: 4.375rem;
  padding-bottom: 2.188rem;
}
.footer .copy {
  color: rgba(255, 255, 255, 0.4);
  text-align: center;
}

.footer-logo {
  margin-bottom: 1.25rem;
}

.social-box ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}
.social-box ul li + li {
  margin-left: 1.25rem;
}
.social-box ul li a {
  color: #ACA8A8;
}
.social-box ul li a:hover, .social-box ul li a:focus {
  color: #FCB606;
}

.footer-link h3 {
  font-size: 1.25rem;
  color: #fff;
  font-family: "Circular-Std-Bold", sans-serif;
  text-transform: uppercase;
  margin-bottom: 1.25rem;
  font-weight: 400;
}
.footer-link ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.footer-link ul li {
  margin-bottom: 0.9375rem;
}
.footer-link ul li a {
  color: #9F9F9F;
  font-family: "Circular-Std-Book", sans-serif;
  text-transform: capitalize;
  position: relative;
  padding-left: 1rem;
}
.footer-link ul li a::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5.938' height='10.385' viewBox='0 0 5.938 10.385'%3E%3Cpath id='Icon_ionic-ios-arrow-forward' data-name='Icon ionic-ios-arrow-forward' d='M15.394,11.387,11.464,7.46a.739.739,0,0,1,0-1.048.748.748,0,0,1,1.051,0l4.452,4.449a.741.741,0,0,1,.022,1.023l-4.471,4.48a.742.742,0,0,1-1.051-1.048Z' transform='translate(-11.246 -6.196)' fill='%239f9f9f'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: left top;
  content: " ";
  width: 6px;
  height: 10px;
  position: absolute;
  left: 0;
  top: 2px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.footer-link ul li a:hover {
  color: #FCB606;
}
.footer-link ul li a:hover::before {
  left: 0.125rem;
}

.home-banner {
  position: relative;
}
.home-banner .filter-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.home-banner .filter-box {
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 0.5rem;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  padding: 1.875rem;
}
@media (min-width: 1200px) {
  .home-banner .filter-box {
    padding-top: 2.5rem;
  }
}
.home-banner .filter-box .filter-heading {
  color: #020000;
  font-family: "Circular-Std-Black", sans-serif;
  margin-bottom: 0.9375rem;
  font-size: 1.375rem;
}
@media (min-width: 768px) {
  .home-banner .filter-box .filter-heading {
    font-size: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .home-banner .filter-box .filter-heading {
    margin-bottom: 1.875rem;
  }
}
.home-banner .filter-box .filter-content {
  margin-left: -1.875rem;
  margin-right: -1.875rem;
}
.home-banner .filter-box .filter-content .nav-tabs {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  border-bottom-color: #8e9fc3;
}
.home-banner .filter-box .filter-content .nav-tabs .nav-link {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 0.625rem 1.5rem 0.375rem;
  font-size: 0.875rem;
  color: #1F2B59;
  font-family: "Circular-Std-Book", sans-serif;
}
.home-banner .filter-box .filter-content .nav-tabs .nav-link:hover, .home-banner .filter-box .filter-content .nav-tabs .nav-link:focus {
  border-color: #8e9fc3 #8e9fc3 #8e9fc3;
  background-color: #fff;
}
.home-banner .filter-box .filter-content .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #8e9fc3 #8e9fc3 #fff;
}
.home-banner .filter-box .filter-content .tab-content {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  padding-top: 1.875rem;
}
.home-banner .carousel-item img {
  object-fit: cover;
  object-position: center;
  height: calc(100vh - 90px);
}
@media (min-width: 768px) {
  .home-banner .carousel-item img {
    height: 30rem;
  }
}
@media (min-width: 1200px) {
  .home-banner .carousel-item img {
    height: auto;
  }
}

.section-rd-sell {
  position: relative;
  padding-bottom: 8.812rem;
  margin-top: 4.375rem;
  min-height: 11rem;
}
@media (min-width: 1200px) {
  .section-rd-sell {
    min-height: 20.5rem;
  }
}
.section-rd-sell::after {
  content: " ";
  position: absolute;
  background-color: #FFFBF2;
  height: 8.812rem;
  left: 0;
  right: 0;
  bottom: 0%;
}
.section-rd-sell .container-rd-sell {
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0;
  right: 0;
  bottom: 0;
}
.section-rd-sell .img-box img {
  width: 470px;
}
.section-rd-sell .sell-box {
  max-width: 570px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 1.25rem;
}

.section-qicksearch {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
}
@media (min-width: 1200px) {
  .section-qicksearch {
    padding-top: 5.625rem;
    padding-bottom: 3.75rem;
  }
}
.section-qicksearch .quicksearch-box figure {
  margin-bottom: 0.625rem;
}
.section-qicksearch .quicksearch-box figure img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}
.section-qicksearch .quicksearch-box .btn-sm {
  min-width: 13.625rem;
  justify-content: center;
}

.section-hiw {
  background-color: #FFFBF2;
  padding-top: 4.375rem;
  padding-bottom: 11.825rem;
  position: relative;
}
.section-hiw .hiw-box figure {
  margin-bottom: 0.3125rem;
}
.section-hiw .hiw-box figure img {
  width: 100%;
  display: block;
  margin: 0 auto;
}
.section-hiw .hiw-box h4 {
  color: #FCB606;
  font-size: 1.125rem;
  text-align: center;
  font-family: "Circular-Std-Bold", sans-serif;
  margin-bottom: 1.25rem;
}
.section-hiw .hiw-box h3 {
  color: #020000;
  font-size: 1.25rem;
  text-align: center;
  font-family: "Circular-Std-Bold", sans-serif;
}

.section-video {
  position: relative;
  margin-bottom: 15.5rem;
}
.section-video .video-wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}
.section-video .video-wrapper .video-box img {
  margin: 0;
  width: 100%;
  max-width: none;
}

.section-popular-brands {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
  background-color: #FFFBF2;
}

.brand-section {
  display: flex;
  gap: 0.9375rem;
  flex-wrap: wrap;
  justify-content: space-between;
}
.brand-section .brand-box {
  border: solid 1px #eee0c2;
  border-radius: 0.5rem;
  background-color: #fff;
  padding: 0.9375rem;
  min-width: 170px;
  height: 130px;
}
.brand-section .brand-box img {
  width: 100%;
  max-width: none;
  object-fit: contain;
  justify-content: center;
}

.section-prinfo {
  background-color: #FFFBF2;
  padding-top: 6.625rem;
  padding-bottom: 4.5rem;
}
.section-prinfo .small-hero {
  margin: 0;
}

.prinfo-box {
  display: flex;
  align-items: center;
}
.prinfo-box figure {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 4.625rem;
  flex: 0 0 4.625rem;
  margin: 0;
  margin-right: 0.625rem;
}
.prinfo-box figure img {
  object-fit: contain;
  object-position: center;
}
.prinfo-box .prinfo-content {
  width: calc(100% - $px84);
  max-width: 100%;
}
.prinfo-box .prinfo-content h3 {
  color: #020000;
  font-size: 1.25rem;
  font-family: "Circular-Std-Bold", sans-serif;
  margin-bottom: 0.9375rem;
}
.prinfo-box .prinfo-content p {
  color: #1F2B59;
  font-family: "Circular-Std-Book", sans-serif;
  margin-bottom: 0;
}

.section-prused {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}
@media (min-width: 768px) {
  .section-prused {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
}

.car-card {
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  background-color: #fff;
  position: relative;
  display: block;
  margin: 0.625rem 0;
}
.car-card figure {
  margin-bottom: 0.3125rem;
  position: relative;
}
.car-card figure img {
  object-fit: cover;
  object-position: center;
  max-width: none;
  width: 100%;
}
.car-card .contain {
  padding: 0.9375rem;
}
.car-card .contain h3 {
  color: #020000;
  font-family: "Circular-Std-Bold", sans-serif;
  font-size: 1.125rem;
  margin-bottom: 0.9375rem;
}
.car-card .contain .location {
  color: #515151;
  font-family: "Circular-Std-Book", sans-serif;
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
  line-height: 1;
}
.car-card .contain .location i {
  width: 15px;
  margin-right: 10px;
}
.car-card .contain .location i img {
  display: block;
}
.car-card .contain .car-badge-box {
  margin-bottom: 0.625rem;
}
.car-card .contain .car-badge-box .car-pill {
  border-radius: 0.5rem;
  background-color: #F8EED6;
  color: #483504;
  font-family: "Circular-Std-Book", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.9375rem;
  padding: 0.5rem;
  min-width: 2.875rem;
}
@media (min-width: 1400px) {
  .car-card .contain .car-badge-box .car-pill {
    min-width: 6.25rem;
  }
}
.car-card .contain .car-badge-box .car-pill + .car-pill {
  margin-left: 0.125rem;
}
.car-card .contain .car-card-footer {
  border-top: solid 2px #8e9fc3;
  padding-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.car-card .contain .car-card-footer .price {
  color: #020000;
  font-size: 1.5rem;
  font-family: "Circular-Std-Bold", sans-serif;
  margin: 0;
}

.customize-controls {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}
.customize-controls li {
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.customize-controls li + li {
  margin-left: 0.625rem;
}
.customize-controls li:hover {
  opacity: 0.3;
}

.wrapper-usedcard-slider .tns-outer {
  margin-left: -5px;
  margin-right: -5px;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.rd-modal {
  max-width: 29.375rem;
}
.rd-modal .modal-header {
  border-bottom: 0;
}
.rd-modal .modal-header .modal-title {
  background-color: #020000;
  font-size: 2.188rem;
  font-family: "Circular-Std-Bold", sans-serif;
  color: #fff;
  padding: 0.625rem 1.875rem;
  border-radius: 0px 8px 8px 0px;
  margin-left: -1rem;
  line-height: 1;
}
.rd-modal .modal-content {
  background-color: #FFFBF2;
  border-radius: 0.5rem;
  border: 0;
}
.rd-modal .modal-body {
  padding-left: 3.125rem;
  padding-right: 3.125rem;
}

.listing-filter {
  background-color: #FFFBF2;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}

.rd-pagination {
  margin-top: 1.25rem;
}
@media (min-width: 992px) {
  .rd-pagination {
    margin-top: 3.125rem;
  }
}
.rd-pagination .page-link {
  border-color: #8E9FC3;
  color: #8E9FC3;
  font-family: "Circular-Std-Book", sans-serif;
  padding: 0.625rem 0.9375rem;
}
.rd-pagination .page-link:hover, .rd-pagination .page-link.active {
  background-color: #F8EED6;
  color: #020000;
}
.rd-pagination .active .page-link {
  background-color: #F8EED6;
  color: #020000;
  border-color: #FCB606;
}

.section-cartype-filter {
  background-color: #FFFBF2;
  padding-top: 2.5rem;
  padding-bottom: 1.25rem;
}
.section-cartype-filter .cartype-title {
  color: #63749B;
  font-family: "Circular-Std-Book", sans-serif;
  line-height: 1;
  margin-bottom: 0.3125rem;
  font-size: 0.875rem;
}

.static-hero {
  position: relative;
}
.static-hero .overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(2, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.static-hero .overlay .heading {
  font-family: "Circular-Std-Black", sans-serif;
  color: #fff;
  margin: 0.5rem;
  font-size: 2.25rem;
}
@media (min-width: 768px) {
  .static-hero .overlay .heading {
    font-size: 2.875rem;
  }
}

.goal-box {
  text-align: center;
  color: #757575;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 1.875rem;
  padding-bottom: 0.9375rem;
}
@media (min-width: 768px) {
  .goal-box {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }
}
.goal-box .goal-content p {
  font-size: 0.875rem;
}
.goal-box h2 {
  font-size: 1.625rem;
  font-family: "Circular-Std-Black", sans-serif;
  margin-bottom: 1.25rem;
  color: #020000;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .goal-box h2 {
    min-height: 3.875rem;
  }
}
@media (min-width: 1200px) {
  .goal-box h2 {
    min-height: auto;
  }
}

.section-best {
  position: relative;
  min-height: 22.5rem;
}
.section-best::after {
  content: " ";
  position: absolute;
  height: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFFBF2;
}

.heading-3 {
  font-size: 1.875rem;
  font-family: "Circular-Std-Black", sans-serif;
  margin-bottom: 2.5rem;
  color: #020000;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 3.125rem;
}

.best-box {
  padding: 2.188rem;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  text-align: center;
  margin: 0 0.9375rem;
  width: calc(100% - 30px);
}
@media (min-width: 768px) {
  .best-box {
    margin: 0;
    width: 100%;
  }
}
.best-box figure {
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.625rem;
}
.best-box h4 {
  font-size: 1.625rem;
  font-family: "Circular-Std-Black", sans-serif;
  margin-bottom: 1.25rem;
  color: #020000;
  text-align: center;
  margin-bottom: 3.125rem;
}
@media (min-width: 768px) {
  .best-box h4 {
    min-height: 3.875rem;
  }
}
@media (min-width: 992px) {
  .best-box h4 {
    min-height: auto;
  }
}

.zindex-10 {
  z-index: 10;
}

.section-whychoose {
  background-color: #FFFBF2;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  color: #757575;
}
.section-whychoose figure img {
  max-width: none;
  width: 100%;
}
.section-whychoose h3 {
  color: #020000;
  font-size: 1.875rem;
  font-family: "Circular-Std-Black", sans-serif;
  text-transform: uppercase;
  margin-bottom: 0.9375rem;
}

.faq-hero {
  background-color: #FFFBF2;
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}
.faq-hero .hdd-4 {
  text-align: center;
  margin-bottom: 3.125rem;
}

.quicksearch .form-label {
  color: #020000;
  font-family: "Circular-Std-Bold", sans-serif;
  margin-bottom: 0.3125rem;
  text-transform: uppercase;
}
.quicksearch .quicksearch-box {
  position: relative;
}
.quicksearch .quicksearch-box input {
  padding-right: 2.375rem;
}
.quicksearch .quicksearch-box input::-moz-placeholder {
  color: #757575;
  opacity: 1;
}
.quicksearch .quicksearch-box input:-ms-input-placeholder {
  color: #757575;
}
.quicksearch .quicksearch-box input::-webkit-input-placeholder {
  color: #757575;
}
.quicksearch .quicksearch-box input:focus {
  border-color: #1F2B59;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(31, 43, 89, 0.25);
}
.quicksearch .quicksearch-box i {
  position: absolute;
  right: 0.625rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.375rem;
  height: 1.375rem;
  color: #757575;
  display: flex;
  justify-content: center;
  align-items: center;
}
.quicksearch .quicksearch-box i svg,
.quicksearch .quicksearch-box i img {
  display: block;
}

.faq-listing {
  padding-top: 3.125rem;
  padding-bottom: 1.25rem;
}

.faq-box {
  margin-bottom: 1.875rem;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
}
.faq-box .faq-header {
  font-family: "Circular-Std-Bold", sans-serif;
  color: #020000;
  display: block;
  padding: 0.9375rem;
  width: 100%;
  font-size: 1rem;
}
.faq-box .faq-header a {
  font-family: "Circular-Std-Bold", sans-serif;
  color: #020000;
}
.faq-box .faq-header a:hover {
  color: #FCB606;
}
.faq-box .faq-body {
  border-top: solid 1px #e6e6e6;
  font-family: "Circular-Std-Book", sans-serif;
  color: #757575;
  padding: 0.9375rem;
}

.static-page-content {
  font-family: "Circular-Std-Book", sans-serif;
  color: #757575;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.static-page-content h5 {
  font-family: "Circular-Std-Black", sans-serif;
  margin-bottom: 0.9375rem;
  color: #020000;
}
.static-page-content .block {
  margin-bottom: 2.5rem;
}

.section-contact {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.contact-box {
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 1.875rem;
  background-color: #fff;
}

.section-product-detail {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}

.product-spec {
  margin-bottom: 1.875rem;
}
.product-spec-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.875rem;
}
.product-spec-body {
  margin: 0;
}

.spec-table .table tr th {
  background-color: #F8F8F8;
  border-color: #E6E6E6;
  font-size: 1rem;
}
.spec-table .table tr th,
.spec-table .table tr td {
  padding: 0.9375rem 0.9375rem;
  vertical-align: middle;
}

.detail-slider-outer {
  position: relative;
}
.detail-slider-outer .tns-controls {
  text-align: center;
  margin: 0;
}
.detail-slider-outer .tns-controls button {
  position: absolute;
  top: 50%;
  z-index: 1;
  background-color: #fff;
  padding: 2px;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  border: 0;
}
.detail-slider-outer .tns-controls button i {
  display: block;
  width: 30px;
  height: 30px;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto;
}
.detail-slider-outer .tns-controls button:first-of-type {
  left: 4px;
}
.detail-slider-outer .tns-controls button:first-of-type i {
  background-image: url("data:image/svg+xml,%3Csvg id='Icon_ionic-ios-arrow-dropright' data-name='Icon ionic-ios-arrow-dropright' xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath id='Path_782' data-name='Path 782' d='M23.437,10.372a1.378,1.378,0,0,0-1.92,0L14.81,17a1.319,1.319,0,0,0-.042,1.841l6.609,6.527a1.373,1.373,0,0,0,1.92.007,1.32,1.32,0,0,0,0-1.89l-5.618-5.613,5.759-5.613A1.306,1.306,0,0,0,23.437,10.372Z' transform='translate(-5.738 -3.375)' fill='%23fcb606'/%3E%3Cpath id='Path_783' data-name='Path 783' d='M32.375,17.875a14.5,14.5,0,1,1-14.5-14.5A14.5,14.5,0,0,1,32.375,17.875Zm-2.231,0A12.264,12.264,0,0,0,9.2,9.2,12.264,12.264,0,1,0,26.547,26.547,12.164,12.164,0,0,0,30.144,17.875Z' transform='translate(-3.375 -3.375)' fill='%23fcb606'/%3E%3C/svg%3E%0A");
}
.detail-slider-outer .tns-controls button:last-of-type {
  right: 4px;
}
.detail-slider-outer .tns-controls button:last-of-type i {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cg id='Icon_ionic-ios-arrow-dropright' data-name='Icon ionic-ios-arrow-dropright' transform='translate(-3.375 -3.375)'%3E%3Cpath id='Path_782' data-name='Path 782' d='M14.815,10.372a1.378,1.378,0,0,1,1.92,0L23.442,17a1.319,1.319,0,0,1,.042,1.841l-6.609,6.527a1.373,1.373,0,0,1-1.92.007,1.32,1.32,0,0,1,0-1.89l5.618-5.613-5.759-5.613A1.306,1.306,0,0,1,14.815,10.372Z' transform='translate(-0.139 0)' fill='%23fcb606'/%3E%3Cpath id='Path_783' data-name='Path 783' d='M3.375,17.875a14.5,14.5,0,1,0,14.5-14.5A14.5,14.5,0,0,0,3.375,17.875Zm2.231,0A12.264,12.264,0,0,1,26.547,9.2,12.264,12.264,0,1,1,9.2,26.547,12.164,12.164,0,0,1,5.606,17.875Z' fill='%23fcb606'/%3E%3C/g%3E%3C/svg%3E%0A");
}
.detail-slider-outer .lt-ie9 .tns-controls > [aria-controls] {
  line-height: 2.5em;
}
.detail-slider-outer [data-action] {
  display: block;
  margin: 10px auto;
  font-size: 17px;
  min-width: 3em;
  text-align: center;
  background: transparent;
  border: 0;
}
.detail-slider-outer .tns-controls [disabled] {
  color: #999999;
  background: #b3b3b3;
  cursor: not-allowed !important;
}
.detail-slider-outer .tns-nav {
  text-align: center;
  margin: 10px 0;
}
.detail-slider-outer .tns-nav > [aria-controls] {
  width: 9px;
  height: 9px;
  padding: 0;
  margin: 0 5px;
  border-radius: 50%;
  background: #ddd;
  border: 0;
}

.customize-tools {
  position: relative;
}
.customize-tools .thumbnails {
  margin: 0 0 1.875rem;
  text-align: center;
  padding: 0;
  display: grid;
  grid-template-columns: 33.3333% 33.3333% 33.3333%;
}
@media (min-width: 992px) {
  .customize-tools .thumbnails {
    grid-column: 1/2;
    grid-template-columns: 50% 50%;
  }
}
.customize-tools .thumbnails li {
  display: inline-block;
  cursor: pointer;
  border: 2px solid transparent;
}
.customize-tools .thumbnails .tns-nav-active {
  background: none;
  border-color: #FCB606;
}
.customize-tools .thumbnails img {
  vertical-align: bottom;
  object-fit: cover;
  object-position: center;
  height: 50px;
  max-height: 50px;
}
@media (min-width: 768px) {
  .customize-tools .thumbnails img {
    height: 95px;
    max-height: 95px;
  }
}
.customize-tools .controls {
  text-align: center;
}
.customize-tools .controls li {
  display: block;
  position: absolute;
  top: 50%;
  height: 60px;
  line-height: 60px;
  margin-top: -30px;
  padding: 0 15px;
  cursor: pointer;
  transition: background 0.3s;
}
.customize-tools .controls li img {
  display: inline-block;
  vertical-align: middle;
}
.customize-tools .controls .prev {
  left: 0;
}
.customize-tools .controls .next {
  right: 0;
}
.customize-tools .controls li:hover {
  background: #f2f2f2;
}

.detail-slider-outer #detail-slider > .tns-item img {
  object-fit: cover;
  height: 300px;
}
@media (min-width: 992px) {
  .detail-slider-outer #detail-slider > .tns-item img {
    height: 500px;
  }
}

.section-product-category {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.category-wrapper {
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  background-color: #fff;
  padding: 1.25rem;
}
.category-wrapper .category-header {
  border-bottom: rgba(142, 159, 195, 0.2) solid 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  margin-bottom: 1.875rem;
  padding: 0 1.25rem 0.9375rem;
}
.category-wrapper .category-header .nav-tabs {
  border: 0;
}
.category-wrapper .category-header .nav-tabs a {
  opacity: 0.15;
}
@media (max-width: 767.98px) {
  .category-wrapper .category-header .nav-tabs a {
    font-size: 1.25rem;
  }
}
.category-wrapper .category-header .nav-tabs a + a {
  margin-left: 1.875rem;
}
.category-wrapper .category-header .nav-tabs a:hover {
  opacity: 1;
}
.category-wrapper .category-header .nav-tabs a.active {
  opacity: 1;
}
.category-wrapper .category-header .nav-tabs a.active:hover {
  color: #020000;
}
.category-wrapper .filter-kit {
  display: flex;
  align-items: center;
}
.category-wrapper .filter-kit label {
  font-size: 0.875rem;
  margin: 0;
  color: #757575;
  font-family: "Circular-Std-Book", sans-serif;
  margin-right: 0.9375rem;
  display: none;
}
@media (min-width: 768px) {
  .category-wrapper .filter-kit label {
    display: block;
  }
}
.category-wrapper .category-body {
  margin: 0;
}
.category-wrapper .category-footer {
  margin: 0;
}

.foldtr {
  position: absolute;
  background-color: #FCB606;
  top: 1.25rem;
  left: 0;
  padding: 0.1875rem 0.625rem;
  color: #fff;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.foldtr::before {
  content: " ";
  position: absolute;
  bottom: 0;
  left: 100%;
  width: 0;
  height: 0;
  border-top: 12px solid #c48003;
  border-right: 12px solid transparent;
}
.foldtr::after {
  content: " ";
  position: absolute;
  left: 100%;
  top: 0;
  height: calc(100% - 8px);
  background-color: #FCB606;
  width: 12px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 6px;
}

.profile-pic figure {
  width: 7.5rem;
  height: 7.5rem;
  min-width: 7.5rem;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 1.25rem 0 0;
}
.profile-pic figure img {
  max-width: none;
  width: 7.5rem;
  object-fit: cover;
  object-position: center;
}

.notification-box ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.notification-box ul li {
  margin-bottom: 1.25rem;
}
.notification-box ul li .control-line {
  position: relative;
  border: 1px solid #8E9FC3;
  border-radius: 0.5rem;
  padding: 0.625rem 1.25rem;
}
.notification-box ul li .control-line::before {
  background-color: #FCB606;
  width: 0.5rem;
  content: " ";
  position: absolute;
  left: -0.0625rem;
  top: -0.0625rem;
  bottom: -0.0625rem;
  border-radius: 8px 0 0 8px;
  z-index: 1;
}
.notification-box ul li .control-line > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background-color: #FCB606;
  border-color: #8E9FC3;
}

.btn-light {
  color: #020000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
}
.btn-light:hover {
  color: #63749b;
  background-color: rgba(252, 182, 6, 0.15);
  border-color: #fcb606;
}

.car-card .contain .location i {
  width: 15px;
  margin-right: 10px;
}

ngb-toast .toast-header .close {
  margin-bottom: 0.25rem !important;
  margin-left: auto !important;
  background: transparent !important;
  border: none !important;
  font-size: 27px !important;
  position: relative !important;
  right: -7px !important;
  top: -10px !important;
}

.listings-inner-corrct.listings-inner {
  padding-bottom: 30px;
  padding-top: 0;
}
@media screen and (min-width: 48em) {
  .listings-inner-corrct.listings-inner {
    padding-top: 30px;
  }
}
.listings-inner-corrct.listings-inner .listings-filter {
  width: 330px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 1501px) {
  .listings-inner-corrct.listings-inner .listings-filter {
    width: 420px;
  }
}
.listings-inner-corrct.listings-inner .listings-list-wrapper {
  width: 100%;
}
.listings-inner-corrct.listings-inner .navigation-thumbs .owl-item img {
  object-fit: cover;
  object-position: center;
  height: 100%;
}
.listings-inner-corrct.listings-inner .product-detail-header {
  padding-right: 0;
  width: 100%;
}
.listings-inner-corrct.listings-inner .product-detail-header .product-title .product-post-name {
  letter-spacing: 0.31px;
  color: #111111;
  font-weight: 600;
  margin: 0;
  font-size: 26px;
  line-height: 1.2;
}
@media screen and (min-width: 48em) {
  .listings-inner-corrct.listings-inner .product-detail-header .product-title .product-post-name {
    font-size: 31px;
  }
}
.listings-inner-corrct.listings-inner .product-detail-header .product-title .product-post-date {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}
.listings-inner-corrct.listings-inner .product-detail-header .product-share-options {
  position: relative;
}
.listings-inner-corrct.listings-inner .product-detail-header .product-share-options > * {
  margin-right: 15px;
}
.listings-inner-corrct.listings-inner .product-detail-header .product-share-options > *:last-child {
  margin-right: 0;
}
.listings-inner-corrct.listings-inner .product-detail-header.bottom {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #e3e3e3;
}
.listings-inner-corrct.listings-inner .product-detail-header.bottom .product-title > span {
  margin: 0;
}
.listings-inner-corrct.listings-inner .product-detail-header.bottom .product-title > span + span {
  margin-left: 20px;
}
.listings-inner-corrct.listings-inner .product-detail-header.bottom .price-tag {
  color: #e95459;
  font-weight: 500;
  font-size: 29px;
  letter-spacing: -0.54px;
}
.listings-inner-corrct.listings-inner .product-detail-header.bottom .feature-tag {
  display: flex;
  align-items: center;
}
.listings-inner-corrct.listings-inner .product-detail-header.bottom .feature-tag.star-tag::before {
  content: " ";
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-right: 10px;
  display: inline-block;
  background: url(/images/star.svg) no-repeat center center;
  background-size: 18px 18px;
}

.xd-msgbox {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 0.6875rem rgba(0, 0, 0, 0.08);
  border: solid 1px rgba(99, 116, 155, 0.25);
}
.xd-msgbox .discussions {
  background-color: #fff;
  overflow-y: auto;
  display: block;
  border-radius: 0.5rem 0 0 0.5rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  height: 600px;
}
.xd-msgbox .discussions .navbar-toggler {
  position: absolute;
  left: 100%;
}
.xd-msgbox .discussions .discussion {
  width: 100%;
  background-color: #fff;
  border-bottom: solid 1px rgba(252, 182, 6, 0.6);
  align-items: center;
  cursor: pointer;
  padding: 0.9375rem;
}
.xd-msgbox .discussions .discussion:last-child {
  border-bottom: 0;
  padding: 0px;
}
.xd-msgbox .discussions .discussion .sb-container {
  max-height: 610px;
}
.xd-msgbox .discussions .discussion .desc-rit {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  min-width: 2.75rem;
}
.xd-msgbox .discussions .discussion .desc-rit .timer {
  margin-left: auto;
  color: #3F3F3F;
  font-size: 10px;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  font-family: "Circular-Std-Book", sans-serif;
}
.xd-msgbox .discussions .discussion .desc-rit .ntfc {
  margin-left: auto;
  margin-top: auto;
  display: inline-flex;
}
.xd-msgbox .discussions .discussion .desc-rit .ntfc i {
  display: flex;
  overflow: hidden;
  min-width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: #1f2b59;
  color: #fff;
  font-size: 0.5625rem;
  font-style: normal;
  justify-content: center;
  align-items: center;
  padding: 4px;
  max-width: 22px;
}
.xd-msgbox .discussions .discussion .desc-rit .ntfc small {
  display: block;
  width: 9px;
  height: 8px;
}
.xd-msgbox .discussions .discussion .desc-rit .ntfc small img {
  display: block;
  width: 9px;
  height: 8px;
}
.xd-msgbox .discussions .search {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e0e0e0;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin-top: 7px;
}
.xd-msgbox .discussions .search .searchbar {
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.xd-msgbox .discussions .search .searchbar i {
  position: absolute;
  left: 0.625rem;
  top: 50%;
  transform: translateY(-50%);
  color: #fcb606;
}
.xd-msgbox .discussions .search .searchbar input {
  border-radius: 0.25rem;
  background-color: rgba(252, 182, 6, 0.1);
  border: 1px solid #fcb606;
  height: 44px;
  width: 100%;
  padding-left: 2.5rem;
  color: #1F2B59;
}
.xd-msgbox .discussions .search .searchbar input::-moz-placeholder {
  color: #020000;
  opacity: 1;
}
.xd-msgbox .discussions .search .searchbar input:-ms-input-placeholder {
  color: #020000;
}
.xd-msgbox .discussions .search .searchbar input::-webkit-input-placeholder {
  color: #020000;
}
.xd-msgbox .discussions .message-active {
  background-color: rgba(252, 182, 6, 0.07);
}
.xd-msgbox .discussions .discussion .name {
  font-size: 0.8125rem;
  font-weight: 400;
  color: #020000;
  margin: 0 0 3px;
  line-height: 14px;
  font-family: "Circular-Std-Bold", sans-serif;
}
.xd-msgbox .discussions .discussion .message {
  margin: 0;
  font-size: 0.8125rem;
  line-height: 1;
  color: #757575;
  font-family: "Circular-Std-Bold", sans-serif;
  padding: 0.1875rem 0;
}
.xd-msgbox .discussions .discussionContainer {
  max-height: calc(100vh - 210px);
}
.xd-msgbox .desc-contact {
  height: 44px;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0.9375rem;
  padding-right: 0.625rem;
  display: flex;
  display: table-caption;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.xd-msgbox .desc-contact * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.xd-msgbox .chat {
  display: block;
  width: 100%;
  background-color: #fff;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media (max-width: 767.98px) {
  .xd-msgbox .chat {
    position: absolute;
    left: -110%;
    top: 0;
  }
}
.xd-msgbox .chat .header-chat {
  background-color: #fff;
  height: 82px;
  display: flex;
  align-items: center;
  padding: 0.9375rem;
  border-bottom: 0.5px solid #f6d6d6;
  justify-content: space-between;
}
.xd-msgbox .chat .header-chat .chat-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.xd-msgbox .chat .header-chat .chat-menu a {
  display: flex;
  width: 30px;
  height: 30px;
  background-color: rgba(252, 182, 6, 0.5);
  padding: 5px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.xd-msgbox .chat .header-chat .chat-menu a svg {
  width: 1rem;
}
.xd-msgbox .chat .header-chat .chat-menu a + a {
  margin-left: 1rem;
}
.xd-msgbox .chat .header-chat .chat-menu a img {
  margin: auto;
  display: flex;
  max-width: 100%;
}
.xd-msgbox .chat .header-chat .chat-menu a:hover {
  background-color: #1f2b59;
  color: #fff;
}
.xd-msgbox .chat .header-chat .chat-menu a:hover img {
  filter: invert(123%) sepia(151%) saturate(182%) hue-rotate(344deg) brightness(193%) contrast(127%);
}
.xd-msgbox .chat .header-chat .icon {
  margin-left: 30px;
  color: #515151;
  font-size: 14pt;
}
.xd-msgbox .chat .header-chat .name {
  margin: 0;
  font-size: 0.875rem;
  color: #020000;
  font-family: "Circular-Std-Bold", sans-serif;
}
.xd-msgbox .chat .header-chat .location {
  margin: auto 0 0;
  color: #757575;
  font-size: 0.8125rem;
  font-family: "Circular-Std-Bold", sans-serif;
}
.xd-msgbox .chat .header-chat .right {
  position: absolute;
  right: 40px;
}
.xd-msgbox .chat .messages-chat {
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  height: calc(100% - 180px);
  max-height: calc(100% - 180px);
}
.xd-msgbox .chat .messages-chat .chat-tod {
  margin: 0;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.xd-msgbox .chat .messages-chat .chat-tod::before {
  content: " ";
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  height: 1px;
  background-color: #d9d9d9;
  display: block;
  position: absolute;
}
.xd-msgbox .chat .messages-chat .chat-tod span {
  background-color: #fff;
  padding: 5px 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #d9d9d9;
  font-size: 12px;
  position: relative;
  z-index: 2;
}
.xd-msgbox .chat .messages-chat .message {
  display: flex;
  align-items: flex-end;
  margin-bottom: 12px;
}
.xd-msgbox .chat .messages-chat .message .photo {
  width: 20px;
  height: 20px;
}
.xd-msgbox .chat .messages-chat .message .photo figure {
  display: block;
  width: 18px;
  height: 18px;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  overflow: hidden;
}
.xd-msgbox .chat .messages-chat .message .photo figure img {
  object-fit: cover;
  object-position: center;
  display: block;
  width: 100%;
  height: 100%;
}
.xd-msgbox .chat .messages-chat .text {
  margin: 0 5px;
  background-color: #F8F8F8;
  padding: 6px 14px;
  border-radius: 10px 10px 10px 0px;
  font-family: "Circular-Std-Book", sans-serif;
  font-size: 0.875rem;
}
.xd-msgbox .chat .messages-scroller {
  max-height: calc(100vh - 270px);
}
@media (min-width: 768px) {
  .xd-msgbox .chat .messages-scroller {
    max-height: calc(100vh - 300px);
  }
}
.xd-msgbox .text-only {
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px;
}
.xd-msgbox .time {
  font-size: 10px;
  color: lightgrey;
  margin-bottom: 10px;
  margin-left: 25px;
}
.xd-msgbox .response-time {
  text-align: right;
  margin-right: 20px !important;
}
.xd-msgbox .response {
  float: right;
  margin-right: 0px !important;
  margin-left: auto;
  /* flexbox alignment rule */
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
}
.xd-msgbox .response .text {
  background-color: #f8eed6 !important;
  border-radius: 10px 10px 0 10px !important;
  color: #1f2b59 !important;
}
.xd-msgbox .footer-chat {
  height: 87px;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #fff;
  border-top: 1px solid #f6d6d6;
  padding-left: 20px;
  padding-right: 20px;
}
.xd-msgbox .footer-chat .icon {
  margin-left: 30px;
  color: #c0c0c0;
  font-size: 14pt;
}
.xd-msgbox .footer-chat .send {
  color: #fff;
  background-color: #4f6ebd;
  position: absolute;
  right: 50px;
  padding: 12px 12px 12px 12px;
  border-radius: 50px;
  font-size: 14pt;
}
.xd-msgbox .footer-chat .name {
  margin: 0 0 0 1.25rem;
  text-transform: uppercase;
  font-size: 13pt;
  color: #515151;
}
.xd-msgbox .footer-chat .right {
  position: absolute;
  right: 40px;
}
.xd-msgbox .footer-chat *::-webkit-input-placeholder {
  color: #c0c0c0;
  font-size: 13pt;
}
.xd-msgbox .footer-chat input *:-moz-placeholder {
  color: #c0c0c0;
  font-size: 13pt;
}
.xd-msgbox .footer-chat input *::-moz-placeholder {
  color: #c0c0c0;
  font-size: 13pt;
  margin-left: 5px;
}
.xd-msgbox .footer-chat input *:-ms-input-placeholder {
  color: #c0c0c0;
  font-size: 13pt;
}
.xd-msgbox .footer-chat .footer-tool {
  background-color: #f9f9f9;
  border-radius: 25px;
  min-height: 50px;
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.xd-msgbox .footer-chat .footer-tool .txtarea {
  width: 100%;
  padding-left: 5px;
  padding-right: 15px;
}
.xd-msgbox .footer-chat .footer-tool .txtarea textarea {
  display: block;
  border: 0;
  background-color: transparent;
  resize: vertical;
  font-size: 14px;
  max-height: 84px;
}
.xd-msgbox .footer-chat .footer-tool .txtarea textarea:focus,
.xd-msgbox .footer-chat .footer-tool .txtarea textarea:hover {
  outline: none;
}
.xd-msgbox .footer-chat .footer-tool .footer-chaticon {
  display: flex;
  padding: 5px;
  height: 50px;
}
.xd-msgbox .footer-chat .footer-tool .footer-chaticon a {
  display: flex;
  align-self: center;
  width: 17px;
  min-width: 17px;
  max-width: 17px;
}
.xd-msgbox .footer-chat .footer-tool .footer-chaticon.icon-group a + a {
  margin-left: 15px;
}
.xd-msgbox .footer-chat .footer-tool .send-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 0;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #020000;
  min-width: 50px;
  position: relative;
  right: -21px;
  top: -2px;
}
.xd-msgbox .write-message {
  border: none !important;
  width: 60%;
  height: 50px;
  margin-left: 1.25rem;
  padding: 10px;
}
.xd-msgbox .clickable {
  cursor: pointer;
}
.xd-msgbox .photo {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: #e6e7ed;
  border: 1px solid #65666b;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.xd-msgbox .photo figure {
  display: block;
  width: 48px;
  height: 48px;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  overflow: hidden;
}
.xd-msgbox .photo figure img {
  object-fit: cover;
  object-position: center;
  height: 100%;
}
.xd-msgbox .status {
  position: absolute;
  top: 32px;
  left: 42px;
  width: 12px;
  height: 12px;
  background-color: #acacac;
  border-radius: 13px;
  border: 1px solid #fff;
}
.xd-msgbox .status.online {
  background-color: #50c958;
}

@media (max-width: 767.98px) {
  body.chat-open #chatbox {
    position: relative;
    left: 0;
  }
  body.chat-open .chat-overly {
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  body.chat-open #discussions {
    position: absolute;
    top: 0;
    left: -150%;
  }
}

.sb-container.discussionContainer .discussion span {
  display: block !important;
  border-bottom: solid 1px rgba(252, 182, 6, 0.6);
  padding: 8px 10px 4px 10px !important;
}

.comment-form {
  position: absolute;
  bottom: 0px;
  width: 75%;
}

.right-box-message {
  border-left: 1px solid rgba(252, 182, 6, 0.6) !important;
}

.mymsg {
  list-style-type: none;
  text-align: right;
}

.mymsg .pic img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  margin-top: 20px;
  margin-right: 6px;
}

.mymsg .msg-text {
  display: inline-block;
  margin-top: 20px;
}

.mymsg .pic {
  display: inline-block;
  float: right;
}

.mymsg .text {
  margin: 0 5px;
  background-color: #eeeeee;
  padding: 6px 14px;
  border-radius: 10px 10px 10px 0px;
  font-family: "Circular-Std-Book", sans-serif;
  font-size: 0.875rem;
}

.mymsg .timetxt {
  color: #757575;
  font-weight: 700;
  font-size: 10px;
  margin-right: 7px;
  margin-top: 5px;
}

.youmsg {
  list-style-type: none;
}

.youmsg .pic img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  margin-top: -50px;
}

.youmsg .msg-text {
  display: inline-block;
  margin-top: 20px;
}

.youmsg .pic {
  display: inline-block;
}

.youmsg .text {
  margin: 0 5px;
  background-color: rgba(252, 182, 6, 0.2);
  padding: 6px 14px;
  border-radius: 10px 10px 10px 0px;
  font-family: "Circular-Std-Book", sans-serif;
  font-size: 0.875rem;
}

.youmsg .timetxt {
  color: #757575;
  padding-left: 6px;
  font-weight: 700;
  font-size: 10px;
  margin-right: 7px;
  margin-top: 5px;
}

.blank-msg {
  height: 600px;
  border: 0px !important;
}

.footer-chat .align-self-center a {
  position: relative;
  left: 8px;
}

.footer-chaticon button {
  position: relative;
  left: 7px;
}

.emoji-mart,
.emoji-mart * {
  box-sizing: border-box;
  line-height: 1.15;
}

.emoji-mart {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  font-size: 16px;
  display: inline-block;
  color: #222427;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #fff;
}

.emoji-mart .emoji-mart-emoji {
  padding: 6px;
}

.emoji-mart-bar {
  border: 0 solid #d9d9d9;
}

.emoji-mart-bar:first-child {
  border-bottom-width: 1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.emoji-mart-bar:last-child {
  border-top-width: 1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.emoji-mart-anchors {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 6px;
  line-height: 0;
}

.emoji-mart-anchor {
  position: relative;
  display: block;
  flex: 1 1 auto;
  color: #858585;
  text-align: center;
  padding: 12px 4px;
  overflow: hidden;
  transition: color 0.1s ease-out;
  margin: 0;
  box-shadow: none;
  background: none;
  border: none;
}

.emoji-mart-anchor:focus {
  outline: 0;
}

.emoji-mart-anchor:hover,
.emoji-mart-anchor:focus,
.emoji-mart-anchor-selected {
  color: #464646;
}

.emoji-mart-anchor-selected .emoji-mart-anchor-bar {
  bottom: 0;
}

.emoji-mart-anchor-bar {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #464646;
}

.emoji-mart-anchors i {
  display: inline-block;
  width: 100%;
  max-width: 22px;
}

.emoji-mart-anchors svg,
.emoji-mart-anchors img {
  fill: currentColor;
  height: 18px;
  width: 18px;
}

.emoji-mart-scroll {
  overflow-y: scroll;
  height: 270px;
  padding: 0 6px 6px 6px;
  will-change: transform;
  /* avoids "repaints on scroll" in mobile Chrome */
}

.emoji-mart-search {
  margin-top: 6px;
  padding: 0 6px;
  position: relative;
}

.emoji-mart-search input {
  font-size: 16px;
  display: block;
  width: 100%;
  padding: 5px 25px 6px 10px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  outline: 0;
}

.emoji-mart-search input,
.emoji-mart-search input::-webkit-search-decoration,
.emoji-mart-search input::-webkit-search-cancel-button,
.emoji-mart-search input::-webkit-search-results-button,
.emoji-mart-search input::-webkit-search-results-decoration {
  /* remove webkit/blink styles for <input type="search">
   * via https://stackoverflow.com/a/9422689 */
  -webkit-appearance: none;
}

.emoji-mart-search-icon {
  position: absolute;
  top: 3px;
  right: 11px;
  z-index: 2;
  padding: 2px 5px 1px;
  border: none;
  background: none;
}

.emoji-mart-category .emoji-mart-emoji span {
  z-index: 1;
  position: relative;
  text-align: center;
  cursor: default;
}

.emoji-mart-category .emoji-mart-emoji:hover:before {
  z-index: 0;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  border-radius: 100%;
}

.emoji-mart-category-label {
  z-index: 2;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.emoji-mart-category-label span {
  display: block;
  width: 100%;
  font-weight: 500;
  padding: 5px 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.95);
}

.emoji-mart-category-list {
  margin: 0;
  padding: 0;
}

.emoji-mart-category-list li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.emoji-mart-emoji {
  position: relative;
  display: inline-block;
  font-size: 0;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
}

.emoji-mart-emoji-native {
  font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji";
}

.emoji-mart-no-results {
  font-size: 14px;
  text-align: center;
  padding-top: 70px;
  color: #858585;
}

.emoji-mart-no-results .emoji-mart-category-label {
  display: none;
}

.emoji-mart-no-results .emoji-mart-no-results-label {
  margin-top: 0.2em;
}

.emoji-mart-no-results .emoji-mart-emoji:hover:before {
  content: none;
}

.emoji-mart-preview {
  position: relative;
  height: 70px;
}

.emoji-mart-preview-emoji,
.emoji-mart-preview-data,
.emoji-mart-preview-skins {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.emoji-mart-preview-emoji {
  left: 12px;
}

.emoji-mart-preview-data {
  left: 68px;
  right: 12px;
  word-break: break-all;
}

.emoji-mart-preview-skins {
  right: 30px;
  text-align: right;
}

.emoji-mart-preview-skins.custom {
  right: 10px;
  text-align: right;
}

.emoji-mart-preview-name {
  font-size: 14px;
}

.emoji-mart-preview-shortname {
  font-size: 12px;
  color: #888;
}

.emoji-mart-preview-shortname + .emoji-mart-preview-shortname,
.emoji-mart-preview-shortname + .emoji-mart-preview-emoticon,
.emoji-mart-preview-emoticon + .emoji-mart-preview-emoticon {
  margin-left: 0.5em;
}

.emoji-mart-preview-emoticon {
  font-size: 11px;
  color: #bbb;
}

.emoji-mart-title span {
  display: inline-block;
  vertical-align: middle;
}

.emoji-mart-title .emoji-mart-emoji {
  padding: 0;
}

.emoji-mart-title-label {
  color: #999A9C;
  font-size: 26px;
  font-weight: 300;
}

.emoji-mart-skin-swatches {
  font-size: 0;
  padding: 2px 0;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  background-color: #fff;
}

.emoji-mart-skin-swatches.custom {
  font-size: 0;
  border: none;
  background-color: #fff;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch {
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch.selected:after {
  opacity: 0.75;
}

.emoji-mart-skin-swatch {
  display: inline-block;
  width: 0;
  vertical-align: middle;
  transition-property: width, padding;
  transition-duration: 0.125s;
  transition-timing-function: ease-out;
}

.emoji-mart-skin-swatch:nth-child(1) {
  transition-delay: 0s;
}

.emoji-mart-skin-swatch:nth-child(2) {
  transition-delay: 0.03s;
}

.emoji-mart-skin-swatch:nth-child(3) {
  transition-delay: 0.06s;
}

.emoji-mart-skin-swatch:nth-child(4) {
  transition-delay: 0.09s;
}

.emoji-mart-skin-swatch:nth-child(5) {
  transition-delay: 0.12s;
}

.emoji-mart-skin-swatch:nth-child(6) {
  transition-delay: 0.15s;
}

.emoji-mart-skin-swatch.selected {
  position: relative;
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatch.selected:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  margin: -2px 0 0 -2px;
  background-color: #fff;
  border-radius: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.emoji-mart-skin-swatch.custom {
  display: inline-block;
  width: 0;
  height: 38px;
  overflow: hidden;
  vertical-align: middle;
  transition-property: width, height;
  transition-duration: 0.125s;
  transition-timing-function: ease-out;
  cursor: default;
}

.emoji-mart-skin-swatch.custom.selected {
  position: relative;
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatch.custom.selected:after {
  content: "";
  width: 0;
  height: 0;
}

.emoji-mart-skin-swatches.custom .emoji-mart-skin-swatch.custom:hover {
  background-color: #f4f4f4;
  border-radius: 10%;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom {
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom.selected:after {
  opacity: 0.75;
}

.emoji-mart-skin-text.opened {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  color: #888;
  font-size: 11px;
  padding: 5px 2px;
  width: 95px;
  height: 40px;
  border-radius: 10%;
  background-color: #fff;
}

.emoji-mart-skin {
  display: inline-block;
  width: 100%;
  padding-top: 100%;
  max-width: 12px;
  border-radius: 100%;
}

.emoji-mart-skin-tone-1 {
  background-color: #ffc93a;
}

.emoji-mart-skin-tone-2 {
  background-color: #fadcbc;
}

.emoji-mart-skin-tone-3 {
  background-color: #e0bb95;
}

.emoji-mart-skin-tone-4 {
  background-color: #bf8f68;
}

.emoji-mart-skin-tone-5 {
  background-color: #9b643d;
}

.emoji-mart-skin-tone-6 {
  background-color: #594539;
}

/* For screenreaders only, via https://stackoverflow.com/a/19758620 */
.emoji-mart-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*
 * Dark mode styles
 */
.emoji-mart-dark {
  color: #fff;
  border-color: #555453;
  background-color: #222;
}

.emoji-mart-dark .emoji-mart-bar {
  border-color: #555453;
}

.emoji-mart-dark .emoji-mart-search input {
  color: #fff;
  border-color: #555453;
  background-color: #2f2f2f;
}

.emoji-mart-dark .emoji-mart-search-icon svg {
  fill: #fff;
}

.emoji-mart-dark .emoji-mart-category .emoji-mart-emoji:hover:before {
  background-color: #444;
}

.emoji-mart-dark .emoji-mart-category-label span {
  background-color: #222;
  color: #fff;
}

.emoji-mart-dark .emoji-mart-skin-swatches {
  border-color: #555453;
  background-color: #222;
}

.emoji-mart-dark .emoji-mart-anchor:hover,
.emoji-mart-dark .emoji-mart-anchor:focus,
.emoji-mart-dark .emoji-mart-anchor-selected {
  color: #bfbfbf;
}

.home-banner .owl-theme .owl-dots {
  bottom: 15px;
}
.home-banner .owl-theme .owl-dots .owl-dot {
  width: 25px;
  height: 5px;
  border: 0px solid;
  border-radius: 0px !important;
  margin: 0px 5px;
}
.home-banner .owl-theme .owl-dots {
  position: absolute;
  width: 100%;
  margin: 0px 0 0 0 !important;
}
.home-banner .owl-theme .owl-dots .owl-dot span {
  width: 25px;
  height: 5px;
  margin: 3px 3px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0px !important;
}
.home-banner .owl-theme .owl-dots .owl-dot.active, .home-banner .owl-theme .owl-dots .owl-dot:hover {
  border: 0px solid;
}
.home-banner .owl-theme .owl-dots .owl-dot.active span, .home-banner .owl-theme .owl-dots .owl-dot:hover span {
  background: #ffffff;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

.modal {
  max-width: none !important;
}

.errtxt {
  position: absolute;
}

.hdd-7, .category-wrapper .category-header .nav-tabs a {
  color: #020000;
  font-size: 20px;
  font-family: "Circular-Std-Bold", sans-serif;
}

.settings .settingbox {
  margin: 0px 0 0 0;
}
.settings .settingbox h2 {
  font-size: 25px;
  color: #f8b408;
  text-transform: uppercase;
  margin: 0px 0 20px 0;
  font-family: 'Circular-Std-Bold", sans-serif';
}
.settings .settingbox .settingline {
  border-bottom: 1px solid #a8bcce;
  padding: 0 0 20px 0;
  margin: 0px 0 25px 0;
}
.settings .settingbox .settingline .title {
  font-size: 15px;
  color: #2D2C2C;
}
.settings .settingbox .settingline .swithbtn {
  float: right;
  margin: -30px 0 0 0;
}
.settings .settingbox .settingline .switch input {
  display: none;
}
.settings .settingbox .settingline .switch {
  display: inline-block;
  width: 34px;
  height: 21px;
  margin: 0px;
  transform: translateY(50%);
  position: relative;
}
.settings .settingbox .settingline .slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  box-shadow: 0 0 0 2px #98999a, 0 0 4px #98999a;
  cursor: pointer;
  border: 4px solid transparent;
  overflow: hidden;
  transition: 0.4s;
}
.settings .settingbox .settingline .slider:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #98999a;
  border-radius: 30px;
  transform: translateX(-13px);
  transition: 0.4s;
}
.settings .settingbox .settingline input:checked + .slider:before {
  transform: translateX(13px);
  background: #517a9e;
}
.settings .settingbox .settingline input:checked + .slider {
  box-shadow: 0 0 0 2px #517a9e, 0 0 2px #517a9e;
}
.settings .settingbox.two h2 {
  margin: 50px 0 20px 0;
}

.category-wrapper {
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  background-color: #fff;
  padding: 1.25rem;
}
.category-wrapper .category-header {
  border-bottom: rgba(142, 159, 195, 0.2) solid 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  margin-bottom: 1.875rem;
  padding: 0 1.25rem 0.9375rem;
}
.category-wrapper .filter-kit {
  display: flex;
  align-items: center;
}
.category-wrapper .filter-kit label {
  font-size: 0.875rem;
  margin: 0;
  color: #757575;
  font-family: "Circular-Std-Book", sans-serif;
  margin-right: 0.9375rem;
  display: none;
}
@media (min-width: 768px) {
  .category-wrapper .filter-kit label {
    display: block;
  }
}
.category-wrapper .category-body {
  margin: 0;
}
.category-wrapper .category-footer {
  margin: 0;
}
.category-wrapper .features-checklist ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.ng-dropdown-panel {
  background-color: #fff !important;
  padding: 10px !important;
  border: 1px solid !important;
}

.ng-clear {
  display: none !important;
}

.faq-page .faq-banner {
  min-height: 150px;
  background: #ECECEC;
  padding: 30px 0 0 0;
  text-align: center;
}
.faq-page .faq-banner h1 {
  font-size: 45px;
  font-family: "AvenirLTStd-Black";
  color: #f8b408;
  margin: 0 0 10px 0;
  text-transform: uppercase;
}
.faq-page .faq-banner .subtitle {
  font-size: 16px;
  color: #f8b408;
  text-transform: uppercase;
  font-family: "AvenirLTStd-Roman";
}
.faq-page .faq-search {
  padding: 40px 0 50px 0;
}
.faq-page .faq-search h2 {
  font-size: 20px;
  font-family: "AvenirLTStd-Black";
  color: #f8b408;
  margin: 0 0 15px 0;
  text-transform: uppercase;
  text-align: center;
}
.faq-page .faq-search .input-group {
  max-width: 504px;
  margin: 0 auto;
}
.faq-page .faq-search .input-group .form-control {
  font-size: 15px;
  border: 1px solid #BCBBBB;
  height: 50px;
  border-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  color: #f8b408;
  box-shadow: none !important;
}
.faq-page .faq-search .input-group .btn-outline-primary {
  background-color: #517A9E;
  border-color: #517A9E;
  color: #fff;
  box-shadow: none;
  font-size: 30px;
  padding: 1px 20px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.faq-page .faq-search .input-group .btn-outline-primary:hover {
  background-color: #517A9E;
  border-color: #517A9E;
  color: #fff;
}
.faq-page .faq-right .card {
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #f8b408;
  margin-bottom: 30px;
}
.faq-page .faq-right .card .card-header {
  padding: 20px 15px;
  background: #fff;
  border-color: #BCBBBB;
}
.faq-page .faq-right .card .card-header .btn-link {
  padding: 0px;
  box-shadow: none;
  text-decoration: none;
}
.faq-page .faq-right .card .card-header .btn-link h5 {
  margin: 0;
  font-size: 15px;
  font-family: "AvenirLTStd-Black";
  color: #f8b408;
}
.faq-page .faq-right .card .card-header .btn-link h5 i {
  font-size: 18px;
}
.faq-page .faq-right .card .card-body {
  font-size: 15px;
  line-height: 25px;
  color: #2D2C2C;
  padding: 20px 15px;
}
.faq-page .faq-right .card .card-body p {
  margin-bottom: 30px;
}

.ng-dropdown-panel {
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  opacity: 0;
  position: absolute;
  width: 100%;
  left: 0px !important;
  top: 44px !important;
  z-index: 1050;
}

.faqtxthead {
  float: left;
  color: #f8b408;
  width: 100%;
  margin-bottom: 0px;
  text-align: left;
}

.my-lg-auto {
  padding: 50px;
}

.file-upload {
  opacity: 0;
  width: 45px;
  height: 45px;
  margin: 0;
  position: absolute;
  left: 0;
  cursor: pointer;
}

.register-page-links {
  text-decoration: underline !important;
  color: #020000 !important;
  cursor: pointer !important;
}

.car-card figure img {
  height: 250px;
}

.msg-list-box .user-info {
  padding: 15.5px;
  border-bottom: solid 1px rgba(252, 182, 6, 0.6);
}

.xd-msgbox, .msg-box-inner-box {
  height: none !important;
}

.message-attach-label {
  backgroun: transparent !important;
}

.terms-and-conditions p {
  color: black !important;
  text-align: justify;
}

.terms-and-conditions p strong {
  margin-bottom: -10px;
  display: block;
}

.msg-list {
  height: 428px;
  overflow-y: scroll;
  padding-left: 7px;
  overflow-x: hidden;
}

.my-msg app-message-item {
  float: right !important;
}

.custom-file {
  height: 142px;
}

.custom-file .custom-file-label {
  padding: 70px;
  text-align: center;
  background-color: #FFFFFF;
  color: #212121;
  border: 1px dashed #CBCBCB;
  height: 142px;
  box-shadow: none;
}

.custom-file .custom-file-label i {
  display: block;
  text-align: center;
  font-size: 35px;
  margin-top: -20px;
  margin-bottom: 10px;
  color: #CBCBCB;
}

.custom-file-label::after {
  display: none;
}

.custom-file .custom-file-input:focus {
  box-shadow: none;
}

.custom-file-upload .custom-file {
  height: 142px;
}
.custom-file-upload .custom-file .custom-file-label {
  padding: 70px;
  text-align: center;
  background-color: #FFFFFF;
  color: #212121;
  border: 1px dashed #CBCBCB;
  height: 142px;
  box-shadow: none;
}
.custom-file-upload .custom-file .custom-file-label i {
  display: block;
  text-align: center;
  font-size: 35px;
  margin-top: -20px;
  margin-bottom: 10px;
  color: #CBCBCB;
}
.custom-file-upload .custom-file-label::after {
  display: none;
}
.custom-file-upload .custom-file .custom-file-input:focus {
  box-shadow: none;
}
.custom-file-upload ul {
  width: 100%;
  padding: 0px;
  list-style: none;
  margin: 0px 0 0 0;
}
.custom-file-upload ul li {
  width: 80px;
  height: 80px;
  padding: 0px;
  display: inline-block;
  margin: 0 10px 0 0;
  border-radius: 4px;
  position: relative;
}
.custom-file-upload Ul li::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}
.custom-file-upload ul li img {
  width: 80px;
  height: 80px;
}
.custom-file-upload ul li .btn-danger {
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 0 5px;
}
.custom-file-upload ul.docs {
  margin: 15px 0 0 0;
}
.custom-file-upload ul.docs li {
  width: 100%;
  height: auto;
  margin: 10px 0px 0 0;
  border-radius: 0px;
  font-size: 14px;
  color: #212121;
  font-family: "Open Sans", sans-serif;
}
.custom-file-upload ul.docs li:first-child {
  margin-top: 0px;
}
.custom-file-upload ul.docs li::before {
  content: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 0px;
  margin: 0 0 0px 0;
}
.custom-file-upload ul.docs li .col .far {
  font-size: 28px;
  color: #3B0975;
}
.custom-file-upload .ng-select.ng-select-single .ng-select-container {
  height: 50px;
  font-size: 15px;
  color: #f8b408;
}
.custom-file-upload .ng-select-container .ng-arrow {
  border-color: #f8b408 transparent transparent;
}
.custom-file-upload .ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  border-color: transparent transparent #f8b408;
}
.custom-file-upload .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.custom-file-upload .input-group-append .btn-outline-secondary {
  border-color: #CBCBCB;
  color: #f8b408;
}
.custom-file-upload .input-group-append .btn-outline-secondary:hover {
  color: #ffffff;
}

.custom-file-input {
  margin: 0;
  opacity: 0;
  overflow: hidden;
  z-index: 2;
  position: absolute;
}

.youmsg .extra-width-div {
  width: calc(80% - 40px);
  float: left;
}

.mymsg .extra-width-div {
  width: calc(80% - 40px);
  float: right;
}

.youmsg {
  list-style-type: none;
  padding-top: 10px;
  padding-bottom: 10px;
}

.msg-list {
  height: 353px;
}

.msg-list {
  padding-left: 15px;
  padding-right: 15px;
}

.header .navbar-nav.secondary-nav .user-dropdown figure img {
  width: 40px !important;
  height: 40px !important;
}

.sign-in-pop {
  width: 180px;
}

.sign-in-pop .ladda-spinner {
  position: relative;
  left: 25px !important;
}

.emoji-mart {
  bottom: 60px !important;
}

.paginationdiv {
  margin-top: 50px !important;
}

.page-link {
  padding: 11px 1rem !important;
  border: 1px solid #8E9FC3 !important;
  color: #8E9FC3 !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: black !important;
  background-color: #F8EED6 !important;
  border-color: #8E9FC3 !important;
}

.page-link:hover {
  z-index: 2;
  color: white !important;
  background-color: black !important;
  border-color: black !important;
}

.page-link:focus {
  color: white !important;
  background-color: black !important;
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25) !important;
}

.page-item:first-child .page-link {
  font-size: 18px !important;
  padding: 8px 1.2rem !important;
}

.page-item:last-child .page-link {
  font-size: 18px !important;
  padding: 8px 1.2rem !important;
}

.navbar-toggler {
  box-shadow: none !important;
}

.modal {
  z-index: 99999;
}

.cap-load-msg {
  width: calc(100% - 88px);
  margin-top: 10px;
}

.new-sugg-layout div {
  overflow: auto;
  overflow-y: hidden;
  white-space: nowrap;
  margin-bottom: 10px;
}

.new-sugg-layout div button {
  margin-bottom: 10px;
  margin-top: 13px;
  margin-right: 8px;
  padding: 10px;
  font-size: 13px;
  border-radius: 50px;
  background: #020000;
  border: #020000;
  width: auto !important;
}

.xd-msgbox .footer-chat {
  height: 165px !important;
}

.new-sugg-layout div::-webkit-scrollbar {
  width: 30px;
  height: 10px;
}

.new-sugg-layout div::-webkit-scrollbar-thumb {
  background: #FCB606;
  border-radius: 20px;
}

.msg-list::-webkit-scrollbar {
  width: 10px;
}

.msg-list::-webkit-scrollbar-thumb {
  background: #020000;
  border-radius: 20px;
}

.msg-list {
  margin-right: 4px;
}

.fake-list-li {
  list-style-type: none;
}

#google_translate_element {
  position: fixed;
  bottom: 20px;
  right: 90px;
  z-index: 9999;
  background: #FCB606;
  padding: 5px 15px;
  border-radius: 6px;
}

#google_translate_element i {
  font-size: 25px;
  height: 0px;
  position: absolute;
  right: -8.5px;
  top: 15px;
  color: #FCB606;
}

.lang-icons {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 99999;
  width: 55px;
  height: 55px;
  background-color: #FCB606;
  border-radius: 50%;
  text-align: center;
  line-height: 55px;
  font-size: 22px;
  cursor: pointer;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

body {
  top: 0px !important;
}

.btn-main {
  align-items: center;
  background-color: #fcb606;
  border-color: #fcb606;
  border-radius: 5px;
  box-shadow: 0 0 9pxrgba 0, 0, 0, 0.16;
  color: #fff;
  display: flex;
  font-family: Circular-Std-Medium, sans-serif;
  font-weight: 400;
  justify-content: center;
  min-height: 38px;
  min-width: 8.125rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}

.btn:focus {
  box-shadow: 0 0 0 -0.75rem rgba(13, 110, 253, 0.25);
}

app-faqs .btn-outline-main {
  color: #FCB606;
  border-color: #FCB606;
  border: 0px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.16);
}

app-faqs .btn-outline-main:hover {
  background-color: transparent;
  color: #FCB606;
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .xd-msgbox .footer-chat {
    width: 75% !important;
    min-height: 87px !important;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .xd-msgbox .footer-chat {
    width: 67% !important;
  }

  .section-qicksearch .quicksearch-box .btn-sm {
    padding-left: 21px !important;
    padding-right: 21px !important;
  }

  .header .navbar-nav.primary-nav .nav-item + .nav-item {
    margin-left: 0px !important;
  }

  .header .navbar-nav.primary-nav .nav-link {
    display: inline-block;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .xd-msgbox .footer-chat {
    width: 58% !important;
    position: relative !important;
  }

  .msg-list {
    height: 369px !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .xd-msgbox .discussions {
    height: auto;
    max-height: 600px;
    padding-bottom: 20px;
  }

  .msg-list {
    height: 306px !important;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  [title=reCAPTCHA] {
    transform: scale(0.77) !important;
    transform-origin: 0 0 !important;
  }

  .right-side-tab {
    border-left: 0px !important;
  }

  .no-border-resp {
    border-top: 0px !important;
  }

  .xd-msgbox .discussions {
    height: auto;
    max-height: 600px;
    padding-bottom: 20px;
  }

  .profile-pic figure {
    margin-right: 0px !important;
  }

  .category-wrapper .category-header .nav-tabs a + a {
    margin-left: 0px !important;
  }
}
@media (max-width: 991px) {
  .header .navbar-nav.primary-nav .nav-link {
    display: inline-block;
  }

  .header .navbar-nav.primary-nav .nav-item + .nav-item {
    margin-left: 0rem;
  }

  .xd-msgbox .footer-chat .footer-tool .send-btn {
    top: -49px;
    margin-right: 22px;
  }

  .text.txtarea {
    padding: 0px !important;
  }

  .footer-chaticon button {
    position: relative;
    left: -4px !important;
  }

  .footer-chat .align-self-center a {
    left: 15px !important;
  }

  .xd-msgbox .footer-chat .footer-tool .footer-chaticon {
    margin-right: 50px;
    position: relative;
    top: 25px;
  }

  .footer-chat .align-self-center a {
    position: relative;
    left: 27px;
    top: -10px;
  }

  .xd-msgbox .footer-chat .footer-tool {
    position: relative;
    top: 25px;
  }

  .xd-msgbox .discussions {
    height: 714px;
  }

  .xd-msgbox .footer-chat {
    height: 263px !important;
  }

  .xd-msgbox .footer-chat .footer-tool {
    background: transparent !important;
  }

  .msg-list {
    height: 337px;
  }
}
@media (max-width: 767px) {
  .home-banner .filter-container {
    position: static;
    transform: translateY(-4%);
  }

  .home-banner .carousel-item img {
    height: 200px;
  }

  .form-control {
    min-height: 46px;
  }

  .btn-main {
    width: 100%;
  }

  .section-rd-sell .sell-box {
    margin-top: 20px;
  }

  .forgot-button-resp {
    width: 130px;
  }

  .xd-msgbox .chat {
    height: 650px;
  }

  .right-box-message {
    border-left: 0px solid rgba(252, 182, 6, 0.6) !important;
  }

  .xd-msgbox .discussions {
    border-radius: 0.5rem !important;
  }

  .xd-msgbox {
    border-radius: 0rem !important;
    border: solid 0px rgba(99, 116, 155, 0.25);
    box-shadow: 0 0 0rem rgba(0, 0, 0, 0.08);
  }

  .div-borders {
    border: solid 1px rgba(99, 116, 155, 0.25) !important;
    border-radius: 0.5rem !important;
  }

  .xd-msgbox .footer-chat {
    left: -1px !important;
    border: solid 1px rgba(99, 116, 155, 0.25) !important;
    border-radius: 0 0 0.5rem 0.5rem !important;
  }

  .footer-chat .align-self-center a {
    position: relative;
    left: -98px !important;
    top: -11px;
  }

  .msg-list {
    height: 400px;
  }

  .custom-flex-box-resp {
    align-items: normal;
  }
}
@media (max-width: 575px) {
  .home-banner .filter-container {
    transform: translateY(-3%);
  }

  .home-banner .filter-box .filter-content .tab-content {
    padding-left: 10px;
    padding-right: 10px;
  }

  .contact-box {
    padding: 1.875rem 10px;
  }

  .rd-modal .modal-body {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }

  .youmsg .extra-width-div {
    width: calc(100% - 40px);
    float: left;
  }

  .mymsg .extra-width-div {
    width: calc(100% - 40px);
    float: right;
  }

  .emoji-mart {
    width: 268px !important;
    position: absolute !important;
    bottom: 65px !important;
    right: none !important;
  }

  .msg-list {
    height: 400px;
  }

  .xd-msgbox {
    width: 100%;
  }

  .footer-chat {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .user-info .name {
    font-size: 12px;
  }

  .user-info .addre {
    font-size: 10px;
  }

  .user-info .view-car-post-butt {
    font-size: 11px;
  }

  .msg-list {
    padding-left: 5px;
    padding-right: 5px;
  }

  .footer-div-left-padding {
    padding-left: 35px;
  }

  .why-chose-cont-left-padd {
    padding-left: 35px;
    padding-right: 35px;
  }

  .card-img {
    width: 20px;
  }

  .car-card .contain .car-card-footer .price {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 140px;
  }

  .new-sugg-layout {
    padding: 0px 20px;
  }

  #chatbox {
    height: 744px;
  }

  .text.txtarea {
    padding: 0px !important;
  }
}
@media (max-width: 416px) {
  .section-rd-sell {
    margin-top: 6.375rem;
  }
}
.wrapper-usedcard-slider .tns-ovh {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.quick-search-icons {
  padding-top: 2px;
}

.faq-page-button {
  padding-top: 2px;
}

.auction-individual-page.live-indi {
  position: relative;
  /* top: 50px; */
  margin-top: 90px;
  bottom: 50px;
}

.header .navbar-nav.secondary-nav .user-dropdown .username {
  display: inline-block;
}

.mat-focus-indicator.mat-icon-button {
  float: right;
  margin-top: -42px;
}

.profile-pic figure {
  margin: 0 0rem 0 0;
  margin-right: auto;
  margin-bottom: 15px;
}

.overflow-tabs-custom {
  overflow-x: auto;
}

.overflow-tabs-custom-tabs {
  width: 500px;
}

.overflow-tabs-custom-tabs a {
  height: 35px;
}

.category-footer.text-end {
  position: relative;
  top: -35px;
}

.control-line.flex-fill input {
  border-radius: 8px 0 0 8px;
}

.form-control.form-select.form-select-xl.control-line::before {
  left: 0px;
  margin-left: -0.9px;
  height: 46px;
  margin-top: -1px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 23px;
  line-height: 34px;
}

.form-check-label {
  color: #757575;
  line-height: 31px !important;
}

.auction-individual-page {
  position: relative;
  /* top: 50px; */
  margin-top: 90px;
  bottom: 50px;
}

.mat-radio-outer-circle, .mat-radio-inner-circle {
  top: -3px !important;
}

.mat-radio-button .mat-radio-ripple {
  top: calc(50% - 23px) !important;
}

.photo {
  width: 50px;
}

.desc-contact {
  width: calc(100% - 50px);
  position: relative;
  top: 7px;
}

.sb-container.discussionContainer .discussion span {
  padding: 8px 10px 8px 10px !important;
}

.mymsg .pic img {
  margin-right: 0px;
}

.youmsg .pic {
  display: inline-block;
  width: 40px !important;
  float: left;
}

.mymsg .pic {
  display: inline-block;
  float: right;
  width: 40px !important;
}

.youmsg .pic img {
  margin-top: 0px;
}

.youmsg .msg-text {
  margin-top: 0px;
}

.xd-msgbox .chat {
  position: static;
  left: 0%;
  top: 0;
  background: transparent;
}

.comment-form {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.blank-msg {
  border-radius: 0.5rem 0.5rem !important;
}

.custom-null-img-msg {
  position: relative;
  left: -10px;
}

.brand-section .brand-box img {
  width: 100%;
  width: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  justify-content: center;
  margin-top: -20px;
}

.cars-brands-title {
  width: 140px;
  bottom: 4px;
  color: #757575;
}

.prinfo-box .prinfo-content p {
  color: #757575;
}

.toast {
  width: 300px !important;
}

.why-chose-cont-left-padd p {
  text-align: justify !important;
}

.rc-anchor-normal.rc-anchor-error .rc-anchor-error-msg-container {
  width: 268px !important;
}

.toast.bg-success .toast-header .close {
  top: 0px !important;
}

.toast.bg-success .toast-body {
  display: none;
}

.toast.bg-success .toast-header {
  border-bottom: 0px solid rgba(0, 0, 0, 0.05) !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  color: black !important;
  font-weight: 100 !important;
  border-left: 5px solid green !important;
}

.toast.bg-success {
  border: 0px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 0rem !important;
}

.toast.bg-warning .toast-header .close {
  top: 0px !important;
}

.toast.bg-warning .toast-body {
  display: none;
}

.toast.bg-warning .toast-header {
  border-bottom: 0px solid rgba(0, 0, 0, 0.05) !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  color: black !important;
  font-weight: 100 !important;
  border-left: 5px solid #ffc107 !important;
}

.toast.bg-warning {
  border: 0px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 0rem !important;
}

.crossbtn {
  border-radius: 50% !important;
  width: 25px !important;
  height: 25px !important;
}

.toast.bg-danger .toast-header .close {
  top: 0px !important;
}

.toast.bg-danger .toast-body {
  display: none;
}

.toast.bg-danger .toast-header {
  border-bottom: 0px solid rgba(0, 0, 0, 0.05) !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  color: black !important;
  font-weight: 100 !important;
  border-left: 5px solid #dc3545 !important;
}

.toast.bg-danger {
  border: 0px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 0rem !important;
}

.crossbtn {
  border-radius: 50% !important;
  width: 25px !important;
  height: 25px !important;
}

.ladda-spinner {
  position: relative;
  left: 20px;
}

@media (min-width: 1440px) {
  .footer-chat {
    width: 75% !important;
  }
}
.xd-msgbox .footer-chat .footer-tool .txtarea textarea {
  box-shadow: none !important;
  resize: none;
  background: #f9f9f9;
  border-radius: 8px;
}

.btn.btn-yes {
  background-color: #fcb606 !important;
  border-color: #fcb606 !important;
}

app-compose .uploads .images {
  max-width: inherit;
  padding-left: 8px;
  padding-bottom: 8px;
  object-fit: contain;
}

app-compose .uploads .images img {
  width: 140px !important;
  height: 120px;
  object-fit: cover;
}

app-compose .uploads .images a {
  position: absolute;
  right: 8px;
  top: 6px;
}

.own-history-below {
  font-size: 20px;
}

.product-spec td {
  text-transform: uppercase;
}

.product-spec th {
  width: 35%;
}