/* You can add global styles to this file, and also import other style files */

//@import "sass/variables";

@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import 'sass/common/variables';
@import 'https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css';
@import 'sass/common/breakpoints';
@import 'sass/common/mixins';
@import 'sass/common/color';
@import 'sass/common/fonts';
@import 'sass/common/pixel';
@import 'sass/common/aspect-ratio';
@import 'sass/base';
@import 'sass/common/utility';
@import 'sass/common/display';
@import 'sass/common/form';
@import 'sass/common/padding';
@import 'sass/common/button';
@import 'sass/common/header';
@import 'sass/common/footer';
@import 'sass/page';
@import 'sass/chat';
@import "~@ctrl/ngx-emoji-mart/picker";


.home-banner {
    .owl-theme .owl-dots {bottom: 15px;}
    .owl-theme .owl-dots .owl-dot {width: 25px; height: 5px; border: 0px solid; border-radius: 0px !important; margin: 0px 5px;}
    .owl-theme .owl-dots {position: absolute; width: 100%; margin: 0px 0 0 0 !important;}
    .owl-theme .owl-dots .owl-dot span {width: 25px; height: 5px; margin: 3px 3px; background: rgba(255, 255, 255, 0.5); border-radius: 0px !important;}
    .owl-theme .owl-dots .owl-dot.active, .owl-theme .owl-dots .owl-dot:hover {border: 0px solid;}
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {background: #ffffff;}
}

.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
}
.modal {max-width: none !important;}
.errtxt{position: absolute;}
.hdd-7 {
    color: #020000;
    font-size: 20px;
    font-family: "Circular-Std-Bold", sans-serif;
}
$primary:#f8b408 !default;
$dark:#2D2C2C !default;
.settings {
    .settingbox {margin: 0px 0 0 0;
        h2 {font-size: 25px; color: $primary; text-transform: uppercase; margin: 0px 0 20px 0; font-family: 'Circular-Std-Bold", sans-serif';}
        .settingline {border-bottom: 1px solid #a8bcce; padding: 0 0 20px 0; margin: 0px 0 25px 0;
            .title {font-size: 15px; color: $dark;}
            .swithbtn {float: right; margin: -30px 0 0 0;}
            .switch input { display:none;}
            .switch {display:inline-block;width:34px;height:21px;margin:0px;transform:translateY(50%);position:relative;}
            .slider {position:absolute;top:0;bottom:0;left:0;right:0;border-radius:30px;box-shadow:0 0 0 2px #98999a, 0 0 4px #98999a;cursor:pointer;border:4px solid transparent;overflow:hidden;transition:.4s;}
            .slider:before {position:absolute;content:"";width:100%;height:100%;background:#98999a;border-radius:30px;transform:translateX(-13px);transition:.4s;}
            input:checked + .slider:before {transform:translateX(13px);background:#517a9e;}
            input:checked + .slider {box-shadow:0 0 0 2px #517a9e,0 0 2px #517a9e;}           
        }
    }
    .settingbox.two {
        h2 {margin: 50px 0 20px 0;}
    }
}

.category-wrapper {
    box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    background-color: $color-white;
    padding: $px20;
    .category-header {
        border-bottom:rgba($color-secondary-200, 0.2) solid 2px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: -$px20;
        margin-right: -$px20;
        margin-bottom: $px30;
        padding: 0 $px20 $px15;
    }
    .filter-kit {
        display: flex;
        align-items: center;
        label {
            font-size: $px14;
            margin: 0;
            color: $color-gray-500;
            font-family: $ffamily-book;
            margin-right: $px15;
            display: none;
            @include bp-above(md) {
                display: block;
            }
        }
    }
    .category-body {
        margin: 0;
    }
    .category-footer {
        margin: 0;
    }
    .features-checklist ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
}

.ng-dropdown-panel {
    background-color: #fff !important;
    padding: 10px !important;
    border: 1px solid !important;
    }
  
.ng-clear { 
    display: none !important;
}

.faq-page {
    .faq-banner {min-height: 150px; background: #ECECEC; padding: 30px 0 0 0; text-align: center;
        h1 {font-size: 45px; font-family: 'AvenirLTStd-Black'; color: $primary; margin: 0 0 10px 0; text-transform: uppercase;}
        .subtitle {font-size: 16px; color: $primary; text-transform: uppercase; font-family: 'AvenirLTStd-Roman';}
    }
    .faq-search {padding: 40px 0 50px 0;
        h2 {font-size: 20px; font-family: 'AvenirLTStd-Black'; color: $primary; margin: 0 0 15px 0; text-transform: uppercase; text-align: center;}
        .input-group {max-width: 504px; margin: 0 auto;
            .form-control {font-size: 15px;border: 1px solid #BCBBBB;height: 50px;border-radius: 8px; border-top-right-radius: 0px; border-bottom-right-radius: 0px; color: $primary; box-shadow: none !important;}
            .btn-outline-primary {background-color: #517A9E; border-color: #517A9E; color: #fff; box-shadow: none; font-size: 30px; padding: 1px 20px; border-top-right-radius: 8px; border-bottom-right-radius: 8px;}
            .btn-outline-primary:hover {background-color: #517A9E; border-color: #517A9E; color: #fff;}
        }
    }
    .faq-right {
        .card {border-radius: 8px; border-width: 1px; border-style: solid; border-color: #f8b408; margin-bottom: 30px;
            .card-header {padding: 20px 15px; background: #fff; border-color: #BCBBBB;
                .btn-link {padding: 0px; box-shadow: none; text-decoration: none;
                    h5 {margin: 0; font-size: 15px; font-family: 'AvenirLTStd-Black'; color: $primary;
                        i {font-size: 18px;}
                    }
                }
            }
            .card-body {font-size: 15px; line-height: 25px; color: $dark; padding: 20px 15px;
                p {margin-bottom: 30px;}
            }
        }
    }
    
    
}

.ng-dropdown-panel {
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    opacity: 0;
    position: absolute;
    width: 100%;
    left: 0px !important;
    top: 44px !important;
    z-index: 1050;
}

.faqtxthead{
    float: left;
    color: #f8b408;
    width: 100%;
    margin-bottom: 0px;
    text-align: left;
} 

.my-lg-auto{
    padding:50px;
}

.file-upload{
    opacity: 0;
    width: 45px;
    height: 45px;
    margin: 0;
    position: absolute;
    left: 0;
    cursor: pointer;
}
.register-page-links { text-decoration: underline !important; color: #020000 !important; cursor: pointer !important;}
.car-card figure img { height: 250px; }
.msg-list-box .user-info { padding: 15.5px; border-bottom: solid 1px rgba(252, 182, 6, 0.6);}
.xd-msgbox, .msg-box-inner-box { height: none !important;}

.message-attach-label { backgroun: transparent !important;}

.terms-and-conditions p { color: black !important; text-align: justify; }

.terms-and-conditions p strong {
    margin-bottom: -10px;
    display: block;
}

.msg-list
{
    height: 428px;
    overflow-y: scroll;
    padding-left: 7px;
    overflow-x: hidden;
}


.my-msg app-message-item { float: right !important; }

.custom-file {height: 142px;}
.custom-file .custom-file-label {padding: 70px;text-align: center;background-color: #FFFFFF;color: #212121;border: 1px dashed #CBCBCB;height: 142px;box-shadow: none;}
.custom-file .custom-file-label i {display: block;text-align: center;font-size: 35px;margin-top: -20px;margin-bottom: 10px;color: #CBCBCB;}
.custom-file-label::after {display: none;}
.custom-file .custom-file-input:focus {box-shadow: none;}



.custom-file-upload {
	.custom-file {height: 142px;}
	.custom-file .custom-file-label {padding: 70px;text-align: center;background-color: #FFFFFF;color: #212121;border: 1px dashed #CBCBCB;height: 142px;box-shadow: none;}
	.custom-file .custom-file-label i {display: block;text-align: center;font-size: 35px;margin-top: -20px;margin-bottom: 10px;color: #CBCBCB;}
	.custom-file-label::after {display: none;}
	.custom-file .custom-file-input:focus {box-shadow: none;}
	ul {width: 100%; padding: 0px; list-style: none; margin: 0px 0 0 0;}
	ul li {width: 80px; height: 80px; padding: 0px; display: inline-block; margin: 0 10px 0 0; border-radius: 4px; position: relative;}
	Ul li::before {content: "";position: absolute;width: 100%;height: 100%;background-color: rgba(0,0,0,.5); border-radius: 4px;}
	ul li img {width: 80px; height: 80px;}
	ul li .btn-danger {position: absolute;top: 2px;right: 2px; padding: 0 5px;}
	ul.docs {margin: 15px 0 0 0;}
	ul.docs li {width: 100%; height: auto; margin: 10px 0px 0 0; border-radius: 0px; font-size: 14px; color: #212121; font-family: 'Open Sans', sans-serif;}
	ul.docs li:first-child {margin-top: 0px;}
	ul.docs li::before {content: inherit;position: absolute;width: 100%;height: 100%;background-color: transparent; border-radius: 0px; margin: 0 0 0px 0;}
	ul.docs li .col .far {font-size: 28px; color: #3B0975;}
	.ng-select.ng-select-single .ng-select-container {height: 50px; font-size: 15px; color: #f8b408;}
	.ng-select-container .ng-arrow {border-color: #f8b408 transparent transparent;}
	.ng-select.ng-select-opened > .ng-select-container .ng-arrow {border-color: transparent transparent #f8b408;}
	.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle) {border-top-right-radius: 4px; border-bottom-right-radius: 4px;}
	.input-group-append .btn-outline-secondary {border-color: #CBCBCB; color: #f8b408;}
	.input-group-append .btn-outline-secondary:hover {color: #ffffff;}
}

.custom-file-input {
    margin: 0;
    opacity: 0;
    overflow: hidden;
    z-index: 2;
    position: absolute;
}

.youmsg .extra-width-div 
{
    width: calc(80% - 40px);
    float: left;
}

.mymsg .extra-width-div 
{
    width: calc(80% - 40px);
    float: right;
}

.youmsg {
    list-style-type: none;
    padding-top: 10px;
    padding-bottom: 10px; 
}

.msg-list 
{
    height: 353px; 
}

.msg-list 
{
    padding-left: 15px;
    padding-right: 15px; 
}

.header .navbar-nav.secondary-nav .user-dropdown figure img {
    width: 40px !important;
    height: 40px !important;
}

.sign-in-pop { width: 180px; }
.sign-in-pop .ladda-spinner { position: relative; left: 25px !important; }
.emoji-mart { bottom: 60px !important; }

.paginationdiv { margin-top: 50px !important; }
.page-link { padding: 11px 1rem !important;  border: 1px solid #8E9FC3 !important; color: #8E9FC3 !important; }    
.page-item.active .page-link {z-index: 3; color: black !important; background-color: #F8EED6 !important; border-color: #8E9FC3 !important;}
.page-link:hover { z-index: 2; color: white !important; background-color: black !important; border-color: black !important;}
.page-link:focus { color: white !important; background-color: black !important; box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%) !important;}   
.page-item:first-child .page-link { font-size: 18px !important; padding: 8px 1.2rem !important; }
.page-item:last-child .page-link { font-size: 18px !important; padding: 8px 1.2rem !important; }
.navbar-toggler { box-shadow: none !important;}
.modal { z-index: 99999;}
.cap-load-msg { width: calc(100% - 88px); margin-top: 10px;}
.new-sugg-layout div { 
    overflow: auto;
    overflow-y: hidden;
    white-space: nowrap;
    margin-bottom: 10px;
}
.new-sugg-layout div button { 
    margin-bottom: 10px;
    margin-top: 13px;
    margin-right: 8px;
    padding: 10px;
    font-size: 13px;
    border-radius: 50px;
    background: #020000;
    border: #020000;
    width: auto !important;
}
.xd-msgbox .footer-chat { height: 165px !important; }

.new-sugg-layout div::-webkit-scrollbar {
    width: 30px;
    height: 10px;
}
.new-sugg-layout div::-webkit-scrollbar-thumb {
    background: #FCB606;
    border-radius: 20px;
}
.msg-list::-webkit-scrollbar {
    width: 10px;
}
.msg-list::-webkit-scrollbar-thumb {
    background: #020000;
    border-radius: 20px;
}
.msg-list {
    margin-right: 4px;
}
.fake-list-li { list-style-type: none;}

// Responsive Starts Here

#google_translate_element {
    position: fixed;
    bottom: 20px;
    right: 90px;
    z-index: 9999;
    background: #FCB606;
    padding: 5px 15px;
    border-radius: 6px;
}

#google_translate_element i
{
    font-size: 25px;
    height: 0px;
    position: absolute;
    right: -8.5px;
    top: 15px;
    color: #FCB606;
}

.lang-icons {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 99999;
    width: 55px;
    height: 55px;
    background-color: #FCB606;
    border-radius: 50%;
    text-align: center;
    line-height: 55px;
    font-size: 22px;
    cursor: pointer;
}

.goog-te-banner-frame.skiptranslate {
    display: none !important;
    } 

body { top: 0px !important;}
.btn-main { align-items: center; background-color: #fcb606; border-color: #fcb606; border-radius: 5px; box-shadow: 0 0 9pxrgba(0,0,0,.16);  color: #fff; display: flex;  font-family: Circular-Std-Medium,sans-serif; font-weight: 400;justify-content: center;  min-height: 38px;  min-width: 8.125rem; padding-left: 1.875rem; padding-right: 1.875rem;}
.btn:focus { box-shadow: 0 0 0 -0.75rem rgb(13 110 253 / 25%)}
app-faqs .btn-outline-main { color: #FCB606;border-color: #FCB606;border: 0px;  box-shadow: 0px 0px 0px rgb(0 0 0 / 16%);}
app-faqs .btn-outline-main:hover{background-color: transparent; color: #FCB606;}

@media (min-width: 1200px) and (max-width: 1440px) 
{
   .xd-msgbox .footer-chat { width: 75% !important; min-height: 87px !important;}
}

@media (min-width: 992px) and (max-width: 1200px) 
{
   .xd-msgbox .footer-chat { width: 67% !important; }
   .section-qicksearch .quicksearch-box .btn-sm 
   {
        padding-left: 21px !important;
        padding-right: 21px !important;
    }

    .header .navbar-nav.primary-nav .nav-item + .nav-item {
        margin-left: 0px !important;
    }
    .header .navbar-nav.primary-nav .nav-link { display: inline-block;}
}

@media (min-width: 768px) and (max-width: 991px) 
{
   .xd-msgbox .footer-chat { width: 58% !important; position: relative !important; }
   .msg-list 
   {
        height: 369px !important;
   }
}

@media (min-width: 576px) and (max-width: 767px) 
{
    .xd-msgbox .discussions {
        height: auto;
        max-height: 600px;
        padding-bottom: 20px;
    }
    .msg-list 
    {
        height: 306px !important;
    }
}

@media (min-width: 320px) and (max-width: 575px) 
{
   [title="reCAPTCHA"] { transform:scale(0.77) !important; transform-origin:0 0 !important; }

   .right-side-tab { border-left: 0px !important;}
   .no-border-resp { border-top: 0px !important; }
    .xd-msgbox .discussions {
        height: auto;
        max-height: 600px;
        padding-bottom: 20px;
    }

    .profile-pic figure{margin-right: 0px!important;}
    .category-wrapper .category-header .nav-tabs a + a { margin-left:0px !important; }
}

@media (max-width: 1282px)
{

}

@media (max-width: 1200px)
{
    
}


@media (max-width: 991px)
{
    .header .navbar-nav.primary-nav .nav-link { display: inline-block; }
    .header .navbar-nav.primary-nav .nav-item + .nav-item { margin-left: 0rem; }
    .xd-msgbox .footer-chat .footer-tool .send-btn 
    {
        top: -49px;
        margin-right: 22px;
    }
    .text.txtarea { padding: 0px !important;}
    .footer-chaticon button {
        position: relative;
        left: -4px !important;
    }
    .footer-chat .align-self-center a {
        left: 15px !important;
    }
    .xd-msgbox .footer-chat .footer-tool .footer-chaticon 
    {
        margin-right: 50px;
        position: relative;
        top: 25px;
    }
    .footer-chat .align-self-center a 
    {
        position: relative;
        left: 27px;
        top: -10px;
    }
    .xd-msgbox .footer-chat .footer-tool 
    {
        position: relative;
        top: 25px;
    }
    .xd-msgbox .discussions { height: 714px;}
    .xd-msgbox .footer-chat 
    {
        height: 263px !important;
    }
    .xd-msgbox .footer-chat .footer-tool { background: transparent !important; }
    .comment-form 
    {
        
    }
    .msg-list 
    {
        height: 337px; 
    }
}

@media (max-width: 767px)
{
    .home-banner .filter-container { position: static; transform: translateY(-4%); }
    .home-banner .carousel-item img { height: 200px; }
    .form-control { min-height: 46px; }
    .btn-main { width: 100%; }
    .section-rd-sell .sell-box { margin-top: 20px; }
    .forgot-button-resp { width: 130px; }
    .xd-msgbox .chat 
    {
        height: 650px;
    }
    .right-box-message 
    {
        border-left: 0px solid rgba(252, 182, 6, 0.6) !important;
    }
    .xd-msgbox .discussions 
    {
    border-radius: 0.5rem !important; 
    }
    .xd-msgbox { border-radius: 0rem !important;  border: solid 0px rgba(99, 116, 155, 0.25); box-shadow: 0 0 0rem rgb(0 0 0 / 8%); }
    .div-borders { border: solid 1px rgba(99, 116, 155, 0.25) !important; border-radius: 0.5rem !important }
    .xd-msgbox .footer-chat 
    {
        left: -1px !important;
        border: solid 1px rgba(99, 116, 155, 0.25) !important; border-radius: 0 0 0.5rem 0.5rem !important
    }
    .footer-chat .align-self-center a 
    {
        position: relative;
        left: -98px !important;
        top: -11px;
    }
    .msg-list 
    {
        height: 400px;
    }
   .custom-flex-box-resp
   {
        align-items: normal;
    }
}

@media (max-width: 575px)
{
    .home-banner .filter-container { transform: translateY(-3%); }
    .home-banner .filter-box .filter-content .tab-content { padding-left: 10px; padding-right: 10px; }
    .contact-box { padding: 1.875rem 10px; }
    .rd-modal .modal-body { padding-left: 1.125rem; padding-right: 1.125rem; }
    .youmsg .extra-width-div 
    {
    width: calc(100% - 40px);
    float: left;
    }
    .mymsg .extra-width-div 
    {
        width: calc(100% - 40px);
        float: right;
    }
    .emoji-mart
    {
    width: 268px !important;
    position: absolute !important;
    bottom: 65px !important;
    right: none !important;
    }
    .msg-list 
    {
        height: 400px;
    }
    .xd-msgbox 
    {
        width: 100%; 
    }
    .footer-chat {padding-left: 0px !important; padding-right: 0px !important; }
    .user-info .name { font-size: 12px; }
    .user-info .addre { font-size: 10px; }
    .user-info .view-car-post-butt { font-size: 11px; }
    .msg-list 
    {
        padding-left: 5px;
        padding-right: 5px;
    }
    .footer-div-left-padding {padding-left: 35px;}
    .why-chose-cont-left-padd {padding-left: 35px; padding-right: 35px;}
    .card-img { width: 20px; }
    .car-card .contain .car-card-footer .price { 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 140px; 
    }  
    .new-sugg-layout {padding: 0px 20px;}
    #chatbox {  height: 744px; }
    .text.txtarea { padding: 0px !important;}
}   

@media (max-width: 416px)
{
    .section-rd-sell { margin-top: 6.375rem; }
} 

.wrapper-usedcard-slider .tns-ovh { padding-left: 0.5rem; padding-right: 0.5rem; }
 
.quick-search-icons { padding-top: 2px; }

.faq-page-button { padding-top: 2px; }

.auction-individual-page.live-indi 
{
    position: relative;
    /* top: 50px; */
    margin-top: 90px;
    bottom: 50px;
}

.header .navbar-nav.secondary-nav .user-dropdown .username 
{
    display: inline-block;
}

.mat-focus-indicator.mat-icon-button { float: right; margin-top: -42px; }

.profile-pic figure 
{
    margin: 0 0rem 0 0; 
    margin-right: auto;
    margin-bottom: 15px; 
}

.overflow-tabs-custom
{ 
    overflow-x: auto; 
}

.overflow-tabs-custom-tabs { width: 500px; }

.overflow-tabs-custom-tabs a { height: 35px; }

.category-footer.text-end { position: relative; top: -35px; }

.control-line.flex-fill input { border-radius: 8px 0 0 8px; }

.form-control.form-select.form-select-xl.control-line::before 
{ 
    left: 0px;
    margin-left: -0.9px;
    height: 46px;
    margin-top: -1px; 
}

.ng-select.ng-select-single .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 23px;
    line-height: 34px;
}

.form-check-label 
{
    color: #757575;
    line-height: 31px !important;
}

.auction-individual-page 
{
    position: relative;
    /* top: 50px; */
    margin-top: 90px;
    bottom: 50px;
}

.mat-radio-outer-circle, .mat-radio-inner-circle
{
    top: -3px !important; 
}

.mat-radio-button .mat-radio-ripple 
{
    top: calc(50% - 23px) !important;
}

.photo { width: 50px; }

.desc-contact { width: calc(100% - 50px); position: relative; top: 7px; }

.sb-container.discussionContainer .discussion span 
{
    padding: 8px 10px 8px 10px !important;
}

.mymsg .pic img 
{
    margin-right: 0px;
}

.youmsg .pic 
{
    display: inline-block;
    width: 40px !important;
    float: left; 
}

.mymsg .pic {
    display: inline-block;
    float: right;
    width: 40px !important;
}

.youmsg .pic img 
{
    margin-top: 0px;
}

.youmsg .msg-text 
{
    margin-top: 0px;
}

.xd-msgbox .chat {
    position: static;
    left: 0%; 
    top: 0; 
    background: transparent;
}

.comment-form {
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.blank-msg 
{
    border-radius: 0.5rem 0.5rem !important;
}

.custom-null-img-msg { position: relative; left: -10px; }

.brand-section .brand-box img 
{
    width: 100%;
    width: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    justify-content: center;
    margin-top: -20px;
}

.cars-brands-title 
{ 
    width: 140px;
    bottom: 4px;
    color: #757575; 
}

.prinfo-box .prinfo-content p 
{
    color: #757575; 
}

.toast 
{
    width: 300px !important;
}

.why-chose-cont-left-padd p { text-align: justify !important; }

// Responsive Ends Here

.rc-anchor-normal.rc-anchor-error .rc-anchor-error-msg-container {
    width: 268px !important;
}

// success message formatting starts here

.toast.bg-success .toast-header .close {
     top: 0px !important;
}

.toast.bg-success .toast-body {
    display: none;
}

.toast.bg-success .toast-header
{
    border-bottom: 0px solid rgba(0,0,0,.05) !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    color: black !important;
    font-weight: 100 !important;
    border-left: 5px solid green !important;
}

.toast.bg-success {
    border: 0px solid rgba(0,0,0,.1) !important; 
    border-radius: 0rem !important;
}

// success message formatting ends here

// warning message formatting starts here

.toast.bg-warning .toast-header .close {
     top: 0px !important;
}

.toast.bg-warning .toast-body {
    display: none;
}

.toast.bg-warning .toast-header
{
    border-bottom: 0px solid rgba(0,0,0,.05) !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    color: black !important;
    font-weight: 100 !important;
    border-left: 5px solid #ffc107 !important;
}

.toast.bg-warning {
    border: 0px solid rgba(0,0,0,.1) !important; 
    border-radius: 0rem !important;
}

.crossbtn { border-radius: 50% !important; width: 25px !important; height: 25px !important; }

// warning message formatting ends here

// danger message formatting starts here

.toast.bg-danger .toast-header .close {
     top: 0px !important;
}

.toast.bg-danger .toast-body {
    display: none;
}

.toast.bg-danger .toast-header
{
    border-bottom: 0px solid rgba(0,0,0,.05) !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    color: black !important;
    font-weight: 100 !important;
    border-left: 5px solid #dc3545 !important;
}

.toast.bg-danger {
    border: 0px solid rgba(0,0,0,.1) !important; 
    border-radius: 0rem !important;
}

.crossbtn { border-radius: 50% !important; width: 25px !important; height: 25px !important; }

// danger message formatting ends here

.ladda-spinner {
    position: relative;
    left: 20px;
}

@media (min-width: 1440px)
{
    .footer-chat { width: 75% !important; }
}

.xd-msgbox .footer-chat .footer-tool .txtarea textarea { box-shadow: none !important; resize: none; background: #f9f9f9; border-radius: 8px;}

.btn.btn-yes
{
    background-color: #fcb606 !important;
    border-color: #fcb606 !important;
}
app-compose .uploads .images{
    max-width: inherit;
    padding-left: 8px;
    padding-bottom: 8px;
    object-fit: contain;
}
app-compose .uploads .images img
{
    width: 140px !important;
    height: 120px;
    object-fit: cover;
}

app-compose .uploads .images a
{
    position: absolute;
    right: 8px;
    top: 6px;
}
.own-history-below { font-size: 20px; }

.product-spec td { text-transform: uppercase; }

.product-spec th { width: 35%; }