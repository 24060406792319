.small-heading {
    color: $color-secondary;
    font-size: $px18;
    font-family: $ffamily-700;
    position: relative;
    padding-left: $px24;
    min-height: $px32;
    display: flex;
    align-items: center;
    &::before {
        position: absolute;
        content: ' ';
        width: $px6;
        height: 100%;
        background-color: $color-primary;
        left: 0;
    }
}

.hdd-1 {
    font-family: $ffamily-900;
    color: $color-gray-800;
    font-size: $px26;
    @include bp-above(md) {
        font-size: $px36;
    }
}
.hdd-2 {
    font-family: $ffamily-700;
    color: $color-gray-800;
    margin-bottom: $px35;
    font-size: $px26;
    @include bp-above(md) {
        font-size: $px36;
    }
}
.hdd-3 {
    position: relative;
    font-family: $ffamily-700;
    color: $color-gray-800;
    margin-bottom: $px35;
    padding-bottom: $px30;
    margin-bottom: $px30;

    font-size: $px26;
    @include bp-above(md) {
        font-size: $px36;
    }
    &::after {
        position: absolute;
        content: ' ';
        left: 0;
        bottom: 0;
        background-color: $color-primary;
        border-radius: $px5;
        height: $px9;
        width: $px60;
    }
}
.hdd-4 {
    color: $color-gray-800;
    font-family: $ffamily-900;
    font-size: $px30;
    @include bp-above(lg){
    font-size: $px46;

    }
}
.hdd-5 {
    color: $color-gray-800;
    font-size: $px20;
    font-family: $ffamily-900;
    margin-bottom: 0;
}
.hdd-6 {
    color: $color-gray-800;
    font-size: $px30;
    font-family: $ffamily-700;
}
.text-black {
    color: $color-black;
}
.hr-black {
    background-color: $color-black;
}

.fs-15 {
    font-size: $px14;
}

.fs-20 {
    font-size: $px20;
}
