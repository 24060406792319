.btn-main {
    color: #fff;
    background-color: $color-primary;
    border-color: $color-primary;
    font-weight: 400;
    border-radius: 5px;
    min-height: 46px;
    padding-left: $px30;
    padding-right: $px30;
    font-family: $ffamily-600;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.16);
    min-width: 8.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        color: #fff;
        background-color: $color-primary-hover;
        border-color: $color-primary-hover;
    }
    &:focus,
    &.focus {
        color: #fff;
        background-color: $color-primary-hover;
        border-color: $color-primary-hover;
        box-shadow: 0 0 0 0.2rem rgba($color-primary, 0.5);
    }
    &.disabled,
    &:disabled {
        color: #fff;
        background-color: $color-gray;
        border-color: $color-gray;
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > .btn-main.dropdown-toggle {
        color: #fff;
        background-color: $color-primary-hover;
        border-color: $color-primary;
    }
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > .btn-main.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba($color-primary, 0.5);
    }
    &.btn-sm {
        min-height: $px40;
        color: $color-gray-800;
        font-family: $ffamily-book;
        i {
            margin-right: $px10;
            min-width: 17px;
        }
        &:hover {
            color: $color-white;
        }
    }
}
.btn-outline-main {
    color: $color-primary;
    border-color: $color-primary;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.16);
    &:hover {
        color: $color-gray-800;
        background-color: $color-primary;
        border-color: $color-primary;
    }
    &:focus {
        box-shadow: 0 0 0 0.25rem rgba($color-primary, 0.5);
    }
}
.add-to-favourite {
    color: $color-primary;
    @include transition(all, 0.2s, ease);
    .heart-stroke {
        color: $color-primary;
        @include transition(all, 0.2s, ease-in-out);
    }
    .heart-fill {
        color: transparent;
    }
    &:hover,
    &.active {
        color: $color-primary;
        .heart-stroke {
            color: $color-primary;
        }
        .heart-fill {
            color: $color-primary;
        }
    }
}
.pdf-download {
    display: inline-flex;
    font-size: $px16;
    color: $color-gray-800;
    font-family: $ffamily-book;
    align-items: center;
    i {
        margin-right: $px10;
        svg,
        img {
            display: block;
        }
    }
}
.product-price-block {
    background-color: $color-primary-100;
    border: 1px solid $color-primary;
    border-radius: 8px;
    padding: $px24;
    h2,
    h3,
    h4,
    h5 {
        font-family: $ffamily-700;
        color: $color-gray-800;
    }
    h2 {
        font-size: $px38;
        margin-bottom: $px20;
        text-align: center;
    }
    h3 {
        text-transform: uppercase;
        font-size: $px26;
        margin-bottom: $px5;
    }
    h4 {
        font-size: $px16;
        margin-bottom: $px5;
    }
    h5 {
        font-family: $ffamily-book;
        font-size: $px14;
        margin-bottom: $px20;
    }
}
