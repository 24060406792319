@font-face {
    font-family: 'Circular-Std-Regular';
    src: url('/assets/fonts/CircularStd-Regular.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Circular-Std-Medium';
    src: url('/assets/fonts/CircularStd-Medium.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular-Std-MediumItalic';
    src: url('/assets/fonts/CircularStd-MediumItalic.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Circular-Std-Bold';
    src: url('/assets/fonts/CircularStd-Bold.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Circular-Std-BoldItalic';
    src: url('/assets/fonts/CircularStd-BoldItalic.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Circular-Std-Black';
    src: url('/assets/fonts/CircularStd-Black.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular-Std-BlackItalic';
    src: url('/assets/fonts/CircularStd-BlackItalic.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Circular-Std-Book';
    src: url('/assets/fonts/CircularStd-Book.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-Book.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular-Std-BookItalic';
    src: url('/assets/fonts/CircularStd-BookItalic.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-BookItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

$ffamily-400: 'Circular-Std-Regular', sans-serif;
$ffamily-600: 'Circular-Std-Medium', sans-serif;
$ffamily-600-italic: 'Circular-Std-MediumItalic', sans-serif;
$ffamily-700: 'Circular-Std-Bold', sans-serif;
$ffamily-700-italic: 'Circular-Std-BoldItalic', sans-serif;
$ffamily-900: 'Circular-Std-Black', sans-serif;
$ffamily-900-italic: 'Circular-Std-BlackItalic', sans-serif;
$ffamily-book: 'Circular-Std-Book', sans-serif;
$ffamily-book-italic: 'Circular-Std-BookItalic', sans-serif;


.family-400{
    font-family: $ffamily-400 !important;
}
.family-600{
    font-family: $ffamily-600 !important;
}
.family-600-italic{
    font-family: $ffamily-600-italic !important;
}
.family-700{
    font-family: $ffamily-700 !important;
}
.family-700-italic{
    font-family: $ffamily-700-italic !important;
}
.family-900{
    font-family: $ffamily-900 !important;
}
.family-900-italic{
    font-family: $ffamily-900-italic !important;
}
.family-book{
    font-family: $ffamily-book !important;
}
.family-book-italic{
    font-family: $ffamily-book-italic !important;
}
