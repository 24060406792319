.listings-inner-corrct {
    &.listings-inner {
        padding-bottom: 30px;
        padding-top: 0;
    }

    @media screen and (min-width: 48em) {
        &.listings-inner {
            padding-top: 30px;
        }
    }

    &.listings-inner .listings-filter {
        width: 330px;
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (min-width: 1501px) {
        &.listings-inner .listings-filter {
            width: 420px;
        }
    }

    &.listings-inner .listings-list-wrapper {
        width: 100%;
    }

    &.listings-inner .navigation-thumbs .owl-item img {
        object-fit: cover;
        object-position: center;
        height: 100%;
    }

    &.listings-inner .product-detail-header {
        padding-right: 0;
        width: 100%;
    }

    &.listings-inner .product-detail-header .product-title .product-post-name {
        letter-spacing: 0.31px;
        color: #111111;
        font-weight: 600;
        margin: 0;
        font-size: 26px;
        line-height: 1.2;
    }

    @media screen and (min-width: 48em) {
        &.listings-inner .product-detail-header .product-title .product-post-name {
            font-size: 31px;
        }
    }

    &.listings-inner .product-detail-header .product-title .product-post-date {
        font-size: 18px;
        font-weight: 400;
        margin: 0;
    }

    &.listings-inner .product-detail-header .product-share-options {
        position: relative;
    }

    &.listings-inner .product-detail-header .product-share-options > * {
        margin-right: 15px;
    }

    &.listings-inner .product-detail-header .product-share-options > *:last-child {
        margin-right: 0;
    }

    &.listings-inner .product-detail-header.bottom {
        padding-top: 20px;
        padding-bottom: 20px;
        border-top: 1px solid #e3e3e3;
    }

    &.listings-inner .product-detail-header.bottom .product-title > span {
        margin: 0;
    }

    &.listings-inner .product-detail-header.bottom .product-title > span + span {
        margin-left: 20px;
    }

    &.listings-inner .product-detail-header.bottom .price-tag {
        color: #e95459;
        font-weight: 500;
        font-size: 29px;
        letter-spacing: -0.54px;
    }

    &.listings-inner .product-detail-header.bottom .feature-tag {
        display: flex;
        align-items: center;
    }

    &.listings-inner .product-detail-header.bottom .feature-tag.star-tag::before {
        content: ' ';
        width: 18px;
        height: 18px;
        vertical-align: middle;
        margin-right: 10px;
        display: inline-block;
        background: url(/images/star.svg) no-repeat center center;
        background-size: 18px 18px;
    }
}

.xd-msgbox {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    border-radius: $px8;
    overflow: hidden;
    position: relative;
    box-shadow: 0 0 $px11 rgba(0, 0, 0, 0.08);
    border: solid 1px rgba($color-secondary-100, 0.25);

    .discussions {
        background-color: $color-white;
        overflow-y: auto;
        display: block;
        //border-right: 1px solid rgba($color-primary, 0.6);
        border-radius: $px8 0 0 $px8;
        @include transition(all, 0.2s, ease-in-out);
        height: 600px;
        
        // height: calc(100vh - 100px);
        // @include bp-above(md) {
        //     height: 700px;
        // }
        .navbar-toggler {
            position: absolute;
            left: 100%;
        }

        .discussion {
            width: 100%;
            background-color: $color-white;
            border-bottom: solid 1px rgba($color-primary, 0.6);
            //display: flex;
            align-items: center;
            cursor: pointer;
            padding: $px15;
            &:last-child {
                border-bottom: 0;
                padding: 0px;
            }
            .sb-container {
                max-height: 610px;
            }

            .desc-rit {
                margin-left: auto;
                display: flex;
                flex-direction: column;
                min-width: $px44;
            }

            .desc-rit .timer {
                margin-left: auto;
                color: $color-gray-200;
                font-size: 10px;
                margin: 0;
                padding: 0;
                margin-bottom: 10px;
                font-family: $ffamily-book;
            }

            .desc-rit .ntfc {
                margin-left: auto;
                margin-top: auto;
                display: inline-flex;
            }

            .desc-rit .ntfc i {
                display: flex;
                overflow: hidden;
                min-width: 14px;
                height: 14px;
                border-radius: 7px;
                background-color: rgba($color-secondary, 1);
                color: #fff;
                font-size: $px9;
                font-style: normal;
                justify-content: center;
                align-items: center;
                padding: 4px;
                max-width: 22px;
            }

            .desc-rit .ntfc small {
                display: block;
                width: 9px;
                height: 8px;
            }

            .desc-rit .ntfc small img {
                display: block;
                width: 9px;
                height: 8px;
            }
        }

        .search {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #e0e0e0;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            margin-top: 7px;

            .searchbar {
                width: 100%;
                padding: 0;
                display: flex;
                align-items: center;
                cursor: pointer;
                position: relative;
                i {
                    position: absolute;
                    left: $px10;
                    top: 50%;
                    transform: translateY(-50%);
                    color: rgba($color-primary, 1);
                }
                input {
                    border-radius: $px4;
                    background-color: rgba($color-primary, 0.1);
                    border: 1px solid rgba($color-primary, 1);
                    height: 44px;
                    width: 100%;
                    padding-left: $px40;
                    color: $color-secondary;
                    @include placeholder($color-gray-800);
                }
            }
        }

        .message-active {
            background-color: rgba($color-primary, 0.07);
        }

        .discussion .name {
            font-size: $px13;
            font-weight: 400;
            color: $color-gray-800;
            margin: 0 0 3px;
            line-height: 14px;
            font-family: $ffamily-700;
            
        }

        .discussion .message {
            margin: 0;
            font-size: $px13;
            line-height: 1;
            color: $color-gray-500;
            font-family: $ffamily-700;
            padding: $px3 0;
            
        }
        .discussionContainer{
            max-height: calc(100vh - 210px);
        }
    }

    .desc-contact {
        height: 44px;
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: $px15;
        padding-right: $px10;
        display: flex;
        display: table-caption;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        & * {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
        }
    }

    .chat {
        display: block;
        width: 100%;
        background-color: $color-white;
        @include transition(all, 0.2s, ease-in-out);

        @include bp-below(md) {
            position: absolute;
            left: -110%;
            top: 0;
        }
        .header-chat {
            background-color: #fff;
            height: 82px;
            display: flex;
            align-items: center;
            padding: $px15;
            border-bottom: 0.5px solid #f6d6d6;
            justify-content: space-between;
        }

        .header-chat .chat-menu {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .header-chat .chat-menu a {
            display: flex;
            width: 30px;
            height: 30px;
            background-color: rgba($color-primary, 0.5);
            padding: 5px;
            border-radius: 50%;
            align-items: center;
            display: flex;
            justify-content: center;
            svg {
                width: $px16;
            }
        }

        .header-chat .chat-menu a + a {
            margin-left: 1rem;
        }

        .header-chat .chat-menu a img {
            margin: auto;
            display: flex;
            max-width: 100%;
        }

        .header-chat .chat-menu a:hover {
            background-color: rgba($color-secondary, 1);
            color: $color-white;
        }

        .header-chat .chat-menu a:hover img {
            filter: invert(123%) sepia(151%) saturate(182%) hue-rotate(344deg) brightness(193%) contrast(127%);
        }

        .header-chat .icon {
            margin-left: 30px;
            color: #515151;
            font-size: 14pt;
        }

        .header-chat {
            .name {
                margin: 0;
                font-size: $px14;
                color: $color-gray-800;
                font-family: $ffamily-700;
            }
            .location {
                margin: auto 0 0;
                color: $color-gray-500;
                font-size: $px13;
                font-family: $ffamily-700;
            }
        }

        .header-chat .right {
            position: absolute;
            right: 40px;
        }

        .messages-chat {
            padding: 1.25rem;
            display: flex;
            flex-direction: column;
            height: calc(100% - 180px);
            max-height: calc(100% - 180px);
        }

        .messages-chat .chat-tod {
            margin: 0;
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .messages-chat .chat-tod::before {
            content: ' ';
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            height: 1px;
            background-color: #d9d9d9;
            display: block;
            position: absolute;
        }

        .messages-chat .chat-tod span {
            background-color: #fff;
            padding: 5px 15px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: #d9d9d9;
            font-size: 12px;
            position: relative;
            z-index: 2;
        }

        .messages-chat .message {
            display: flex;
            align-items: flex-end;
            margin-bottom: 12px;
        }

        .messages-chat .message .photo {
            width: 20px;
            height: 20px;
        }

        .messages-chat .message .photo figure {
            display: block;
            width: 18px;
            height: 18px;
            border: 0;
            padding: 0;
            margin: 0;
            border-radius: 50%;
            overflow: hidden;
        }

        .messages-chat .message .photo figure img {
            object-fit: cover;
            object-position: center;
            display: block;
            width: 100%;
            height: 100%;
        }

        .messages-chat .text {
            margin: 0 5px;
            background-color: $color-gray-300;
            padding: 6px 14px;
            border-radius: 10px 10px 10px 0px;
            font-family: $ffamily-book;
            font-size: $px14;
        }
        .messages-scroller {
            max-height: calc(100vh - 270px);
            @include bp-above(md) {
                max-height: calc(100vh - 300px);
            }
        }
    }

    .text-only {
        margin-left: 20px;
        margin-right: 20px;
        border-radius: 10px;
    }

    .time {
        font-size: 10px;
        color: lightgrey;
        margin-bottom: 10px;
        margin-left: 25px;
    }

    .response-time {
        text-align: right;
        margin-right: 20px !important;
    }

    .response {
        float: right;
        margin-right: 0px !important;
        margin-left: auto;
        /* flexbox alignment rule */
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;
    }

    .response .text {
        background-color: rgba($color-primary-200, 1) !important;
        border-radius: 10px 10px 0 10px !important;
        color: rgba($color-secondary, 1) !important;
    }

    .footer-chat {
        height: 87px;
        display: flex;
        align-items: center;
        position: relative;
        background-color: #fff;
        border-top: 1px solid #f6d6d6;
        padding-left: 20px;
        padding-right: 20px;
    }

    .footer-chat .icon {
        margin-left: 30px;
        color: #c0c0c0;
        font-size: 14pt;
    }

    .footer-chat .send {
        color: #fff;
        background-color: #4f6ebd;
        position: absolute;
        right: 50px;
        padding: 12px 12px 12px 12px;
        border-radius: 50px;
        font-size: 14pt;
    }

    .footer-chat .name {
        margin: 0 0 0 1.25rem;
        text-transform: uppercase;
        font-size: 13pt;
        color: #515151;
    }

    .footer-chat .right {
        position: absolute;
        right: 40px;
    }

    .footer-chat *::-webkit-input-placeholder {
        color: #c0c0c0;
        font-size: 13pt;
    }

    .footer-chat input *:-moz-placeholder {
        color: #c0c0c0;
        font-size: 13pt;
    }

    .footer-chat input *::-moz-placeholder {
        color: #c0c0c0;
        font-size: 13pt;
        margin-left: 5px;
    }

    .footer-chat input *:-ms-input-placeholder {
        color: #c0c0c0;
        font-size: 13pt;
    }

    .footer-chat .footer-tool {
        background-color: #f9f9f9;
        border-radius: 25px;
        min-height: 50px;
        display: flex;
        align-items: flex-end;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .footer-chat .footer-tool .txtarea {
        width: 100%;
        padding-left: 5px;
        padding-right: 15px;
    }

    .footer-chat .footer-tool .txtarea textarea {
        display: block;
        border: 0;
        background-color: transparent;
        resize: vertical;
        font-size: 14px;
        max-height: 84px;
    }

    .footer-chat .footer-tool .txtarea textarea:focus,
    .footer-chat .footer-tool .txtarea textarea:hover {
        outline: none;
    }

    .footer-chat .footer-tool .footer-chaticon {
        display: flex;
        padding: 5px;
        height: 50px;
    }

    .footer-chat .footer-tool .footer-chaticon a {
        display: flex;
        align-self: center;
        width: 17px;
        min-width: 17px;
        max-width: 17px;
    }

    .footer-chat .footer-tool .footer-chaticon.icon-group a + a {
        margin-left: 15px;
    }

    .footer-chat .footer-tool .send-btn {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 0;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-gray-800;
        min-width: 50px;
        position: relative;
        right: -21px;
        top: -2px;
    }

    .write-message {
        border: none !important;
        width: 60%;
        height: 50px;
        margin-left: 1.25rem;
        padding: 10px;
    }

    .clickable {
        cursor: pointer;
    }

    .photo {
        display: inline-block;
        width: 50px;
        height: 50px;
        background: #e6e7ed;
        border: 1px solid #65666b;
        -moz-border-radius: 25px;
        -webkit-border-radius: 25px;
        border-radius: 25px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
    }

    .photo figure {
        display: block;
        width: 48px;
        height: 48px;
        border: 0;
        padding: 0;
        margin: 0;
        border-radius: 50%;
        overflow: hidden;
    }

    .photo figure img {
        object-fit: cover;
        object-position: center;
        height: 100%;
    }

    .status {
        position: absolute;
        top: 32px;
        left: 42px;
        width: 12px;
        height: 12px;
        background-color: #acacac;
        border-radius: 13px;
        border: 1px solid #fff;
    }

    .status.online {
        background-color: #50c958;
    }
}
body.chat-open {
    @include bp-below(md){
        #chatbox {
            position: relative;
            left: 0;
        }
        .chat-overly {
            width: 100%;
            height: 100%;
            z-index: 10;
        }
        #discussions {
            position: absolute;
            top: 0;
            left: -150%;
            
        }
    }
}

.sb-container.discussionContainer .discussion span { display: block !important; border-bottom: solid 1px rgba(252, 182, 6, 0.6); padding: 8px 10px 4px 10px !important; }

.comment-form
{
    position: absolute;
    bottom: 0px;
    width: 75%;
}

.right-box-message { border-left: 1px solid rgba(252, 182, 6, 0.6) !important; }

.mymsg { list-style-type: none; text-align: right;}
.mymsg .pic img { width: 40px; height: 40px; object-fit: cover; object-position: center; border-radius: 50%; margin-top: 20px;  margin-right: 6px; }
.mymsg .msg-text { display: inline-block; margin-top: 20px;}
.mymsg .pic { display: inline-block; float: right; }
.mymsg .text { margin: 0 5px;
    background-color: #eeeeee;
    padding: 6px 14px;
    border-radius: 10px 10px 10px 0px;
    font-family: "Circular-Std-Book", sans-serif;
    font-size: 0.875rem; }
.mymsg .timetxt { color: #757575;
    font-weight: 700;
    font-size: 10px;
    margin-right: 7px;
    margin-top: 5px; }

.youmsg { list-style-type: none;}
.youmsg .pic img { width: 40px; height: 40px; object-fit: cover; object-position: center; border-radius: 50%; margin-top: -50px; }
.youmsg .msg-text { display: inline-block; margin-top: 20px;}
.youmsg .pic { display: inline-block; }
.youmsg .text { margin: 0 5px;
    background-color: rgba(252, 182, 6, 0.2);
    padding: 6px 14px;
    border-radius: 10px 10px 10px 0px;
    font-family: "Circular-Std-Book", sans-serif;
    font-size: 0.875rem; }
.youmsg .timetxt { color: #757575;
    padding-left: 6px;
    font-weight: 700;
    font-size: 10px;
    margin-right: 7px;
    margin-top: 5px; }

    .blank-msg
    {
       height: 600px;
       border: 0px !important;
    }

    .footer-chat .align-self-center a { position: relative; left: 8px; }
    .footer-chaticon button { position: relative; left: 7px; }