html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  padding:$px66 0 0;
  font-family: $ffamily-400;
  font-weight: 400;
  color: $color-gray-800;
  font-size: $px14;
  @include bp-above(md){
    padding-top: $px90;
  }
}
a,
button {
  @include transition(all, 0.2s, ease-in-out);
}
a {
  color: $color-primary;
  text-decoration: none;
}

a:hover {
  color: $color-primary-hover;
  text-decoration: none;
}

input:focus,
button:focus,
a:focus {
  outline-width: 0;
  outline-style: solid;
  outline-color: transparent;
}

button:focus,
a:focus {
  outline: none;
}

img {
  max-width: 100%;
}
