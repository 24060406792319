.home-banner {
    position: relative;
    .filter-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }
    .filter-box {
        background-color: rgba($color-white, 0.95);
        border-radius: $px8;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        padding: $px30;
        @include bp-above(xl) {
            padding-top: $px40;
        }
        .filter-heading {
            color: $color-gray-800;
            font-family: $ffamily-900;
            margin-bottom: $px15;
            font-size: $px22;
            @include bp-above(md) {
                font-size: $px40;
            }
            @include bp-above(xl) {
                margin-bottom: $px30;
            }
        }

        .filter-content {
            margin-left: -$px30;
            margin-right: -$px30;
            .nav-tabs {
                padding-left: $px30;
                padding-right: $px30;
                border-bottom-color: #8e9fc3;
                .nav-link {
                    border-top-left-radius: $px8;
                    border-top-right-radius: $px8;
                    padding: $px10 $px24 $px6;
                    font-size: $px14;
                    color: $color-secondary;
                    font-family: $ffamily-book;
                    &:hover,
                    &:focus {
                        border-color: #8e9fc3 #8e9fc3 #8e9fc3;
                        background-color: #fff;
                    }
                    &.active {
                        color: #495057;
                        background-color: #fff;
                        border-color: #8e9fc3 #8e9fc3 #fff;
                    }
                }
            }
            .tab-content {
                padding-left: $px30;
                padding-right: $px30;
                padding-top: $px30;
            }
        }
    }
    .carousel-item img {
        object-fit: cover;
        object-position: center;
        height: calc(100vh - 90px);
        @include bp-above(md) {
            height: 30rem;
        }
        @include bp-above(xl) {
            height: auto;
        }
    }
}
.section-rd-sell {
    position: relative;
    padding-bottom: 8.812rem;
    margin-top: $px70;
    min-height: 11rem;
    @include bp-above(xl) {
        min-height: 20.5rem;
    }
    &::after {
        content: ' ';
        position: absolute;
        background-color: $color-primary-100;
        height: 8.812rem;
        left: 0;
        right: 0;
        bottom: 0%;
    }
    .container-rd-sell {
        position: absolute;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .img-box img {
        width: 470px;
    }
    .sell-box {
        max-width: 570px;
        width: 100%;
        background-color: $color-white;
        box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
        padding: $px20;
    }
}
.section-qicksearch {
    padding-top: $px50;
    padding-bottom: $px50;
    @include bp-above(xl) {
        padding-top: $px90;
        padding-bottom: $px60;
    }
    .quicksearch-box {
        figure {
            margin-bottom: $px10;
            img {
                display: block;
                max-width: 100%;
                margin: 0 auto;
            }
        }
        .btn-sm {
            min-width: 13.625rem;
            justify-content: center;
        }
    }
}
.section-hiw {
    background-color: $color-primary-100;
    padding-top: $px70;
    padding-bottom: 11.825rem;
    position: relative;

    .hiw-box {
        figure {
            margin-bottom: $px5;
            img {
                width: 100%;
                display: block;
                margin: 0 auto;
            }
        }
        h4 {
            color: $color-primary;
            font-size: $px18;
            text-align: center;
            font-family: $ffamily-700;
            margin-bottom: $px20;
        }
        h3 {
            color: $color-gray-800;
            font-size: $px20;
            text-align: center;
            font-family: $ffamily-700;
        }
    }
}
.section-video {
    position: relative;
    margin-bottom: 15.5rem;
    .video-wrapper {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        .video-box {
            img {
                margin: 0;
                width: 100%;
                max-width: none;
            }
        }
    }
}
.section-popular-brands {
    padding-top: $px50;
    padding-bottom: $px50;
    background-color: $color-primary-100;
}
.brand-section {
    display: flex;
    gap: $px15;
    flex-wrap: wrap;
    justify-content: space-between;
    .brand-box {
        border: solid 1px #eee0c2;
        border-radius: $px8;
        background-color: $color-white;
        padding: $px15;
        min-width: 170px;
        height: 130px;
        img {
            width: 100%;
            max-width: none;
            object-fit: contain;
            justify-content: center;
        }
    }
}
.section-prinfo {
    background-color: $color-primary-100;
    padding-top: 6.625rem;
    padding-bottom: $px72;
    .small-hero {
        margin: 0;
    }
}
.prinfo-box {
    display: flex;
    align-items: center;
    figure {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: $px74;
        flex: 0 0 $px74;
        margin: 0;
        margin-right: $px10;
        img {
            object-fit: contain;
            object-position: center;
        }
    }
    .prinfo-content {
        width: calc(100% - $px84);
        max-width: 100%;
        h3 {
            color: $color-gray-800;
            font-size: $px20;
            font-family: $ffamily-700;
            margin-bottom: $px15;
        }
        p {
            color: $color-secondary;
            font-family: $ffamily-book;
            margin-bottom: 0;
        }
    }
}
.section-prused {
    padding-top: $px30;
    padding-bottom: $px30;
    @include bp-above(md) {
        padding-top: $px72;
        padding-bottom: $px72;
    }
}
.car-card {
    box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    background-color: $color-white;
    position: relative;
    display: block;
    margin: $px10 0;
    figure {
        margin-bottom: $px5;
        position: relative;
        img {
            object-fit: cover;
            object-position: center;
            max-width: none;
            width: 100%;
        }
    }
    .contain {
        padding: $px15;
        h3 {
            color: $color-gray-800;
            font-family: $ffamily-700;
            font-size: $px18;
            margin-bottom: $px15;
        }
        .location {
            color: $color-gray-900;
            font-family: $ffamily-book;
            display: flex;
            align-items: center;
            margin-bottom: $px20;
            line-height: 1;
            i {
                width: 15px;
                margin-right: 10px;
                img {
                    display: block;
                }
            }
        }
        .car-badge-box {
            margin-bottom: $px10;
            .car-pill {
                border-radius: $px8;
                background-color: $color-primary-200;
                color: $color-gray-950;
                font-family: $ffamily-book;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: $px15;
                padding: $px8;
                min-width: $px46;
                @include bp-above(xxl) {
                    min-width: $px100;
                }
                & + .car-pill {
                    margin-left: $px2;
                }
            }
        }
        .car-card-footer {
            border-top: solid 2px #8e9fc3;
            padding-top: $px16;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .price {
                color: $color-gray-800;
                font-size: $px24;
                font-family: $ffamily-700;
                margin: 0;
            }
        }
    }
}
.customize-controls {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    li {
        cursor: pointer;
        @include transition(all, 0.2s, ease);
        & + li {
            margin-left: $px10;
        }
        &:hover {
            opacity: 0.3;
        }
    }
}
.wrapper-usedcard-slider {
    .tns-outer {
        margin-left: -5px;
        margin-right: -5px;
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}
.rd-modal {
    max-width: 29.375rem;
    .modal-header {
        border-bottom: 0;
        .modal-title {
            background-color: $color-gray-800;
            font-size: $px35;
            font-family: $ffamily-700;
            color: $color-white;
            padding: $px10 $px30;
            border-radius: 0px 8px 8px 0px;
            margin-left: -$px16;
            line-height: 1;
        }
    }
    .modal-content {
        background-color: $color-primary-100;
        border-radius: $px8;
        border: 0;
    }
    .modal-body {
        padding-left: $px50;
        padding-right: $px50;
    }
}
.listing-filter {
    background-color: $color-primary-100;
    padding-top: $px30;
    padding-bottom: $px30;
}
.rd-pagination {
    margin-top: $px20;
    @include bp-above(lg) {
        margin-top: $px50;
    }
    .page-link {
        border-color: $color-secondary-200;
        color: $color-secondary-200;
        font-family: $ffamily-book;
        padding: $px10 $px15;
        &:hover,
        &.active {
            background-color: $color-primary-200;
            color: $color-gray-800;
        }
    }

    .active {
        .page-link {
            background-color: $color-primary-200;
            color: $color-gray-800;
            border-color: $color-primary;
        }
    }
}
.section-cartype-filter {
    background-color: $color-primary-100;
    padding-top: $px40;
    padding-bottom: $px20;
    .cartype-title {
        color: $color-secondary-100;
        font-family: $ffamily-book;
        line-height: 1;
        margin-bottom: $px5;
        font-size: $px14;
    }
}
.static-hero {
    position: relative;
    .overlay {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background-color: rgba($color-gray-800, 0.3);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .heading {
            font-family: $ffamily-900;
            color: $color-white;
            margin: $px8;
            font-size: $px36;
            @include bp-above(md) {
                font-size: $px46;
            }
        }
    }
}
.goal-box {
    text-align: center;
    color: $color-gray-500;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: $px30;
    padding-bottom: $px15;
    @include bp-above(md) {
        padding-top: $px70;
        padding-bottom: $px70;
    }
    .goal-content {
        p {
            font-size: $px14;
        }
    }
    h2 {
        font-size: $px26;
        font-family: $ffamily-900;
        margin-bottom: $px20;
        color: $color-gray-800;
        text-transform: uppercase;
        @include bp-above(md) {
            min-height: $px62;
        }
        @include bp-above(xl) {
            min-height: auto;
        }
    }
}

.section-best {
    position: relative;
    min-height: 22.5rem;
    &::after {
        content: ' ';
        position: absolute;
        height: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color-primary-100;
    }
}
.heading-3 {
    font-size: $px30;
    font-family: $ffamily-900;
    margin-bottom: $px40;
    color: $color-gray-800;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: $px50;
}
.best-box {
    padding: $px35;
    box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.08);
    background-color: $color-white;
    text-align: center;
    margin: 0 $px15;
    width: calc(100% - 30px);
    @include bp-above(md) {
        margin: 0;
        width: 100%;
    }
    figure {
        height: 58px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: $px10;
    }
    h4 {
        font-size: $px26;
        font-family: $ffamily-900;
        margin-bottom: $px20;
        color: $color-gray-800;
        text-align: center;
        margin-bottom: $px50;

        @include bp-above(md) {
            min-height: $px62;
        }
        @include bp-above(lg) {
            min-height: auto;
        }
    }
}
.zindex-10 {
    z-index: 10;
}
.section-whychoose {
    background-color: $color-primary-100;
    padding-top: $px40;
    padding-bottom: $px40;
    color: $color-gray-500;

    figure {
        img {
            max-width: none;
            width: 100%;
        }
    }
    h3 {
        color: $color-gray-800;
        font-size: $px30;
        font-family: $ffamily-900;
        text-transform: uppercase;
        margin-bottom: $px15;
    }
}
.faq-hero {
    background-color: $color-primary-100;
    padding-top: $px60;
    padding-bottom: $px60;
    .hdd-4 {
        @extend.hdd-4;
        text-align: center;
        margin-bottom: $px50;
    }
}
.quicksearch {
    .form-label {
        color: $color-gray-800;
        font-family: $ffamily-700;
        margin-bottom: $px5;
        text-transform: uppercase;
    }
    .quicksearch-box {
        position: relative;
        input {
            padding-right: $px38;
            @include placeholder($color-gray-500);
            &:focus {
                border-color: $color-secondary;
                outline: 0;
                box-shadow: 0 0 0 0.25rem rgba($color-secondary, 0.25);
            }
        }
        i {
            position: absolute;
            right: $px10;
            top: 50%;
            transform: translateY(-50%);
            width: $px22;
            height: $px22;
            color: $color-gray-500;
            display: flex;
            justify-content: center;
            align-items: center;
            svg,
            img {
                display: block;
            }
        }
    }
}
.faq-listing {
    padding-top: $px50;
    padding-bottom: $px20;
}
.faq-box {
    margin-bottom: $px30;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    .faq-header {
        font-family: $ffamily-700;
        color: $color-gray-800;
        display: block;
        padding: $px15;
        width: 100%;
        font-size: $px16;
        a {
            font-family: $ffamily-700;
            color: $color-gray-800;
            &:hover {
                color: $color-primary;
            }
        }
        // &[aria-expanded="true"]{
        //     border-bottom-color: $color-gray-500;
        // }
    }
    .faq-body {
        border-top: solid 1px #e6e6e6;
        font-family: $ffamily-book;
        color: $color-gray-500;
        padding: $px15;
    }
}
.static-page-content {
    font-family: $ffamily-book;
    color: $color-gray-500;
    padding-top: $px40;
    padding-bottom: $px40;
    h5 {
        font-family: $ffamily-900;
        margin-bottom: $px15;
        color: $color-gray-800;
    }
    .block {
        margin-bottom: $px40;
    }
}
.section-contact {
    padding-top: $px40;
    padding-bottom: $px40;
}
.contact-box {
    box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: $px30;
    background-color: $color-white;
}
.section-product-detail {
    padding-top: $px30;
    padding-bottom: $px30;
}
.product-spec {
    margin-bottom: $px30;
    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $px30;
    }
    &-body {
        margin: 0;
    }
}
.spec-table {
    .table {
        tr {
            th {
                background-color: $color-gray-300;
                border-color: $color-gray-400;
                font-size: $px16;
            }
            th,
            td {
                padding: $px15 $px15;
                vertical-align: middle;
            }
        }
    }
}
.detail-slider-outer {
    position: relative;
    .tns-controls {
        text-align: center;
        margin: 0;
        button {
            position: absolute;
            top: 50%;
            z-index: 1;
            background-color: $color-white;
            padding: 2px;
            border-radius: 50%;
            width: 34px;
            height: 34px;
            border: 0;
            i {
                display: block;
                width: 30px;
                height: 30px;
                background-position: center;
                background-repeat: no-repeat;
                margin: 0 auto;
            }
            &:first-of-type {
                left: 4px;
                i {
                    background-image: url("data:image/svg+xml,%3Csvg id='Icon_ionic-ios-arrow-dropright' data-name='Icon ionic-ios-arrow-dropright' xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath id='Path_782' data-name='Path 782' d='M23.437,10.372a1.378,1.378,0,0,0-1.92,0L14.81,17a1.319,1.319,0,0,0-.042,1.841l6.609,6.527a1.373,1.373,0,0,0,1.92.007,1.32,1.32,0,0,0,0-1.89l-5.618-5.613,5.759-5.613A1.306,1.306,0,0,0,23.437,10.372Z' transform='translate(-5.738 -3.375)' fill='%23fcb606'/%3E%3Cpath id='Path_783' data-name='Path 783' d='M32.375,17.875a14.5,14.5,0,1,1-14.5-14.5A14.5,14.5,0,0,1,32.375,17.875Zm-2.231,0A12.264,12.264,0,0,0,9.2,9.2,12.264,12.264,0,1,0,26.547,26.547,12.164,12.164,0,0,0,30.144,17.875Z' transform='translate(-3.375 -3.375)' fill='%23fcb606'/%3E%3C/svg%3E%0A");
                }
            }
            &:last-of-type {
                right: 4px;
                i {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cg id='Icon_ionic-ios-arrow-dropright' data-name='Icon ionic-ios-arrow-dropright' transform='translate(-3.375 -3.375)'%3E%3Cpath id='Path_782' data-name='Path 782' d='M14.815,10.372a1.378,1.378,0,0,1,1.92,0L23.442,17a1.319,1.319,0,0,1,.042,1.841l-6.609,6.527a1.373,1.373,0,0,1-1.92.007,1.32,1.32,0,0,1,0-1.89l5.618-5.613-5.759-5.613A1.306,1.306,0,0,1,14.815,10.372Z' transform='translate(-0.139 0)' fill='%23fcb606'/%3E%3Cpath id='Path_783' data-name='Path 783' d='M3.375,17.875a14.5,14.5,0,1,0,14.5-14.5A14.5,14.5,0,0,0,3.375,17.875Zm2.231,0A12.264,12.264,0,0,1,26.547,9.2,12.264,12.264,0,1,1,9.2,26.547,12.164,12.164,0,0,1,5.606,17.875Z' fill='%23fcb606'/%3E%3C/g%3E%3C/svg%3E%0A");
                }
            }
        }
    }

    .lt-ie9 .tns-controls > [aria-controls] {
        line-height: 2.5em;
    }
    [data-action] {
        display: block;
        margin: 10px auto;
        font-size: 17px;
        min-width: 3em;
        text-align: center;
        background: transparent;
        border: 0;
    }
    .tns-controls [disabled] {
        color: #999999;
        background: #b3b3b3;
        cursor: not-allowed !important;
    }
    .tns-nav {
        text-align: center;
        margin: 10px 0;
    }
    .tns-nav > [aria-controls] {
        width: 9px;
        height: 9px;
        padding: 0;
        margin: 0 5px;
        border-radius: 50%;
        background: #ddd;
        border: 0;
    }
}
.customize-tools {
    position: relative;
    .thumbnails {
        margin: 0 0 $px30;
        text-align: center;
        padding: 0;
        display: grid;
        grid-template-columns: 33.3333% 33.3333% 33.3333%;
        @include bp-above(lg) {
            grid-column: 1/2;
            grid-template-columns: 50% 50%;
        }
    }
    .thumbnails li {
        display: inline-block;
        cursor: pointer;
        border: 2px solid transparent;
    }
    .thumbnails .tns-nav-active {
        background: none;
        border-color: $color-primary;
    }

    .thumbnails img {
        vertical-align: bottom;
        object-fit: cover;
        object-position: center;
        height: 50px;
        max-height: 50px;
        @include bp-above(md) {
            height: 95px;
            max-height: 95px;
        }
    }
    .controls {
        text-align: center;
    }
    .controls li {
        display: block;
        position: absolute;
        top: 50%;
        height: 60px;
        line-height: 60px;
        margin-top: -30px;
        padding: 0 15px;
        cursor: pointer;
        transition: background 0.3s;
    }
    .controls li img {
        display: inline-block;
        vertical-align: middle;
    }
    .controls .prev {
        left: 0;
    }
    .controls .next {
        right: 0;
    }
    .controls li:hover {
        background: #f2f2f2;
    }
}
.detail-slider-outer #detail-slider > .tns-item img {
    object-fit: cover;
    height: 300px;
    @include bp-above(lg) {
        height: 500px;
    }
}
.section-product-category {
    padding-top: $px40;
    padding-bottom: $px40;
}
.category-wrapper {
    box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    background-color: $color-white;
    padding: $px20;
    .category-header {
        border-bottom: rgba($color-secondary-200, 0.2) solid 2px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: -$px20;
        margin-right: -$px20;
        margin-bottom: $px30;
        padding: 0 $px20 $px15;
        .nav-tabs {
            border: 0;
            a {
                opacity: 0.15;
                @extend .hdd-7;
                @include bp-below(md) {
                    font-size: $px20;
                }
                + a {
                    margin-left: $px30;
                }
                &:hover {
                    opacity: 1;
                }
                &.active {
                    opacity: 1;
                    &:hover {
                        color: rgba($color-gray-800, 1);
                    }
                }
            }
        }
    }
    .filter-kit {
        display: flex;
        align-items: center;
        label {
            font-size: $px14;
            margin: 0;
            color: $color-gray-500;
            font-family: $ffamily-book;
            margin-right: $px15;
            display: none;
            @include bp-above(md) {
                display: block;
            }
        }
    }
    .category-body {
        margin: 0;
    }
    .category-footer {
        margin: 0;
    }
}
.foldtr {
    position: absolute;
    background-color: $color-primary;
    top: $px20;
    left: 0;
    padding: $px3 $px10;
    color: $color-white;
    font-size: $px16;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 100%;
        width: 0;
        height: 0;
        border-top: 12px solid #c48003;
        border-right: 12px solid transparent;
    }
    &::after {
        content: ' ';
        position: absolute;
        left: 100%;
        top: 0;
        height: calc(100% - 8px);
        background-color: $color-primary;
        width: 12px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 6px;
    }
    // &::after {
    //     content: ' ';
    //     position: absolute;
    //     top: 0%;
    //     right: 0%;
    //     width: 0px;
    //     height: 0px;
    //     border-top: 69px solid #272822;
    //     border-left: 69px solid transparent;
    // }
}
.profile-pic {
    figure {
        width: 7.5rem;
        height: 7.5rem;
        min-width: 7.5rem;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 $px20 0 0;
        img {
            max-width: none;
            width: 7.5rem;
            object-fit: cover;
            object-position: center;
        }
    }
}
.notification-box {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            margin-bottom: $px20;
            .control-line {
                position: relative;
                border: 1px solid $color-secondary-200;
                border-radius: $px8;
                padding: $px10 $px20;
                &::before {
                    background-color: $color-primary;
                    width: $px8;
                    content: ' ';
                    position: absolute;
                    left: -$px1;
                    top: -$px1;
                    bottom: -$px1;
                    border-radius: 8px 0 0 8px;
                    z-index: 1;
                }
                > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
                    border-top-right-radius: $px8;
                    border-bottom-right-radius: $px8;
                    background-color: $color-primary;
                    border-color: $color-secondary-200;
                }
            }
        }
    }
}

.btn-light {
    color: rgba($color-gray-800, 1);
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    &:hover {
        color: rgba($color-secondary-100, 1);
        background-color: rgba($color-primary, 0.15);
        border-color: rgba($color-primary, 1);
    }
}

.car-card .contain .location i {
    width: 15px;
    margin-right: 10px;
}

ngb-toast .toast-header .close {
    margin-bottom: 0.25rem !important;
    margin-left: auto !important;
    background: transparent !important;
    border: none !important;
    font-size: 27px !important;
    position: relative !important;
    right: -7px !important;
    top: -10px !important;
}