.form-select-xl {
    border-radius: $px8;
    border: 1px solid #8e9fc3;
    background-image: url("data:image/svg+xml,%3Csvg id='Group_3' data-name='Group 3' xmlns='http://www.w3.org/2000/svg' width='11.701' height='12.701' viewBox='0 0 11.701 12.701'%3E%3Cpath id='Icon_ionic-md-arrow-dropup' data-name='Icon ionic-md-arrow-dropup' d='M9,19.351,14.851,13.5,20.7,19.351Z' transform='translate(-9 -13.5)' fill='%23485885'/%3E%3Cpath id='Icon_ionic-md-arrow-dropup-2' data-name='Icon ionic-md-arrow-dropup' d='M9,13.5l5.851,5.851L20.7,13.5Z' transform='translate(-9 -6.649)' fill='%23485885'/%3E%3C/svg%3E%0A");
    background-size: 12px 13px;
    height: $px46;
    font-family: $ffamily-book;
    color: $color-secondary-100;
    &:disabled {
        background-color: $color-white;
        opacity: 0.5;
    }
    &:focus {
        color: $color-secondary;
        background-color: #fff;
        border-color: $color-secondary;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba($color-secondary, 0.25);
    }
}
.form-control-xl {
    border-radius: $px8;
    border: 1px solid #8e9fc3;
    height: $px46;
    font-family: $ffamily-book;
    color: $color-secondary-100;
    &:disabled {
        background-color: $color-white;
        opacity: 0.5;
    }
    &:focus {
        color: $color-secondary;
        background-color: #fff;
        border-color: $color-secondary;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba($color-secondary, 0.25);
    }
}
.custome-form-controls {
    .form-label {
        color: $color-secondary-100;
        font-family: $ffamily-book;
        margin-bottom: $px5;
    }
    .control-line {
        position: relative;
        &::before {
            background-color: $color-primary;
            width: $px8;
            content: ' ';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            border-radius: 8px 0 0 8px;
        }
    }
    .form-control {
        border-color: $color-secondary-200;
        border-radius: $px8;
        padding-left: $px16;
        padding-right: $px16;
        min-height: $px46;
        &:focus {
            color: $color-secondary;
            background-color: #fff;
            border-color: $color-secondary;
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgba($color-secondary, 0.25);
        }
    }
}
.link-secondary {
    color: $color-secondary;
}
.btn-group-xl {
    .btn {
        padding: 10px $px10;
    }
    .btn-primary {
        background-color: $color-white;
        border-color: $color-secondary-200;
        color: $color-secondary-100;
        border-radius: $px8;
    }
    .btn-check:checked + .btn-primary,
    .btn-check:active + .btn-primary,
    .btn-primary:active,
    .btn-primary.active,
    .show > .btn-primary.dropdown-toggle {
        background-color: $color-primary;
        border-color: $color-primary;
    }
    .btn-check:checked + .btn-primary:focus,
    .btn-check:active + .btn-primary:focus,
    .btn-primary:active:focus,
    .btn-primary.active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.25rem rgba($color-primary, 0.5);
    }
    .btn-check:focus + .btn-primary, .btn-primary:focus {
        color: #fff;
        background-color: $color-primary;
        border-color: $color-primary;
        box-shadow: 0 0 0 0.25rem rgba($color-primary, 0.5);
    }
}
