.header {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .navbar-brand {
        padding-top: 0;
        padding-bottom: 0;

        img {
            height: 50px;
            @include bp-above(md) {
                height: auto;
            }
        }
    }
    .navbar-nav {
        &.primary-nav {
            .nav-item {
                + .nav-item {
                    @include bp-above(md){
                        margin-left: $px15;
                    }
                }
            }
            .nav-link {
                font-size: $px14;
                color: $color-secondary;
                font-family: $ffamily-book;
                position: relative;
                &::after {
                    content: ' ';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 6px;
                    background-color: $color-primary;
                    text-transform: uppercase;
                    transform: scale(0);
                    opacity: 0;
                    @include transition(all, 0.2s, ease);
                }
                &:hover,
                &:focus,
                &.active {
                    color: $color-primary;
                    &::after {
                        transform: scale(1);
                        opacity: 0.36;
                    }
                }
                .icon {
                    height: $px22;
                    display: block;
                    svg {
                        height: 22px;
                    }
                }
            }
        }
        &.secondary-nav {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .nav-item {
                & + .nav-item {
                    margin-left: $px20;
                }
            }
            .nav-link {
                font-size: $px14;
                position: relative;
                color: $color-secondary;
                font-family: $ffamily-600;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                &:hover,
                &:focus,
                &.active {
                    color: $color-primary;
                }
                &.btn-signin {
                    background-color: $color-primary;
                    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.16);
                    border-radius: $px8;
                    color: $color-white;
                    padding-left: $px18;
                    padding-right: $px18;
                    display: flex;
                    align-items: center;
                    &:hover,
                    &:focus,
                    &.active {
                        background-color: $color-primary-hover;
                    }
                    i {
                        display: block;
                        margin-right: $px6;
                        max-width: $px17;
                    }
                    span {
                        display: block;
                        line-height: 1;
                    }
                }
            }
            .user-dropdown {
                margin-left: 10px;
                .nav-link {
                    display: flex;
                    align-items: center;
                }
                .username {
                    display: none;
                    @include bp-above(md) {
                        display: inline-block;
                    }
                }
                figure {
                    width: $px40;
                    height: $px40;
                    border-radius: 50%;
                    overflow: hidden;
                    margin: 0;
                    margin-right: $px10;
                    img {
                        object-fit: cover;
                        object-position: center;
                    }
                }
                .dropdown-menu {
                    background-color: $color-gray-800;
                        @include bp-below(lg){
                            position: absolute;
                            right: 0;
                        }
                    .dropdown-item {
                        color: $color-white;
                        display: flex;
                        align-items: center;
                        i {
                            width: $px18;
                            height: $px18;
                            display: inline-block;
                            margin-right: $px8;

                            img {
                                display: block;
                            }
                        }
                        &:hover,
                        &.active {
                            background-color: $color-primary;
                        }
                    }
                }
            }
        }
    }
}
.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba($color-primary,0.5);
}