$color-primary: #FCB606;
$color-primary-hover: rgb(230, 153, 11);
$color-primary-100: #FFFBF2;
$color-primary-200: #F8EED6;

$color-secondary: #1F2B59;
$color-secondary-100: #63749B;
$color-secondary-200: #8E9FC3;
$color-secondary-hover: #181818;
$color-white: #fff;
$color-black: #000000;
$color-gray: #7c7c7c;
$color-gray-200: #3F3F3F;
$color-gray-300: #F8F8F8;
$color-gray-400: #E6E6E6;
$color-gray-500: #757575;
$color-gray-600: #9F9F9F;
$color-gray-700: #ACA8A8;
$color-gray-800: #020000;
$color-gray-900: #515151;
$color-gray-950: #483504;

//Text
